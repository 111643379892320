import { getAxiosRequest } from '@swarmion/serverless-contracts';

import { initConnectionContract } from '@dialog/notification-contracts';

import { createAuthClient } from 'services/networking/client';

export const getSessionId = async (): Promise<string> => {
  const result = await getAxiosRequest<typeof initConnectionContract>(
    initConnectionContract,
    await createAuthClient(),
    {},
  );

  return result.data.sid;
};
