import { Suspense } from 'react';

import { PageLoader } from 'components/shared';

export const WithSuspense = ({
  children,
  suspense,
}: {
  children: JSX.Element | JSX.Element[];
  suspense: boolean;
}): JSX.Element => {
  if (suspense) {
    return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
  }

  return <>{children}</>;
};
