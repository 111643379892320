import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const getGroupsFromSession = (
  session: CognitoUserSession | undefined,
): string[] => {
  if (session === undefined) {
    return [];
  }

  return (
    (session.getAccessToken().payload['cognito:groups'] as
      | string[]
      | undefined) ?? []
  );
};

export const getOrgFromSession = (
  session: CognitoUserSession | undefined,
): string | undefined => {
  if (session === undefined) {
    return;
  }

  const groups =
    (session.getAccessToken().payload['cognito:groups'] as
      | string[]
      | undefined) ?? [];

  if (groups[0] === undefined) {
    return;
  }

  if (groups[0] !== 'admin') {
    return groups[0];
  }

  return groups[1] ?? undefined;
};

export const getNameFromToken = (
  session: CognitoUserSession | undefined,
): string => {
  if (session === undefined) {
    console.error('Session is undefined while trying to fetch name');

    return '';
  }

  return session.getIdToken().payload.name as string;
};

export const getEmailFromToken = (
  session: CognitoUserSession | undefined,
): string => {
  if (session === undefined) {
    console.error('Session is undefined while trying to fetch name');

    return '';
  }

  return session.getIdToken().payload.email as string;
};
