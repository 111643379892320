import { update } from '@intercom/messenger-js-sdk';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { usePostHog } from 'posthog-js/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OrganizationUser } from '@dialog/auth-contracts';

import { PageLoader } from 'components/shared/PageLoader/PageLoader';
import { fetchCurrentUserData, refreshUserSession } from 'services/fetch';
import { shouldTrackUser } from 'utils/tracking';

interface authContext {
  currentUser?: OrganizationUser;
  refreshCurrentUserData: () => Promise<void>;
  saveSessionInState: () => Promise<void>;
  session?: CognitoUserSession;
  username?: string;
  profilePicture?: string;
}

export const AuthContext = createContext<authContext>({
  refreshCurrentUserData: () =>
    Promise.resolve(console.error('Auth context not well initialized')),
  saveSessionInState: () =>
    Promise.resolve(console.error('Auth context not well initialized')),
});
export const useAuth = (): authContext => useContext(AuthContext);

export const AuthProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<CognitoUserSession>();
  const [currentUser, setCurrentUser] = useState<OrganizationUser>();

  const postHog = usePostHog();

  const refreshCurrentUserData = useCallback(async () => {
    const newSession = await refreshUserSession();
    const user = await fetchCurrentUserData();
    setSession(newSession);
    setCurrentUser(user);
    setLoading(false);
  }, []);

  useEffect(() => {
    const identifyUser = () => {
      if (currentUser !== undefined) {
        update({
          email: currentUser.email,
          name: currentUser.name,
        });
        if (
          currentUser.email !== undefined &&
          shouldTrackUser(currentUser.email)
        ) {
          postHog.identify(currentUser.email, {
            name: currentUser.name,
            email: currentUser.email,
          });
        }
      }
    };
    void identifyUser();
  }, [postHog, currentUser]);

  const saveSessionInState = useCallback(async () => {
    try {
      const sessionResult = await Auth.currentSession();
      setSession(sessionResult);

      await refreshCurrentUserData();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [refreshCurrentUserData]);

  useEffect(() => {
    const setUserData = async () => {
      try {
        await refreshCurrentUserData();
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    void setUserData();
  }, [refreshCurrentUserData]);

  const contextValue = useMemo(() => {
    return {
      session,
      refreshCurrentUserData,
      saveSessionInState,
      currentUser,
      profilePicture: session?.getIdToken().payload.picture as
        | string
        | undefined,
      username: session?.getAccessToken().payload.username as string,
    };
  }, [session, currentUser, refreshCurrentUserData, saveSessionInState]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
