import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { SentryErrorBoundary } from 'pages/Errors/SentryErrorBoundary';

import {
  IRoutesTreeConfiguration,
  routesTreeConfiguration,
} from './AppRoutingConfiguration';
import { RouteBuilder } from './RouteBuilder';

const AppRoutes = (): JSX.Element => {
  const router = createBrowserRouter(displayRoutes(routesTreeConfiguration));

  return <RouterProvider router={router} />;
};

const displayRoutes = (routes: IRoutesTreeConfiguration[]): RouteObject[] => {
  return routes.map(
    ({
      path,
      element,
      index,
      suspense,
      onlyLoggedOut,
      protected: protectedRoute,
      requireOrganization,
      walkthroughModalActivated,
      children,
    }) => {
      return {
        path,
        element: RouteBuilder({
          suspense: suspense ?? false,
          protected: protectedRoute ?? false,
          onlyLoggedOut: onlyLoggedOut ?? false,
          walkthroughModalActivated: walkthroughModalActivated ?? false,
          requireOrganization: requireOrganization ?? false,
          element: <SentryErrorBoundary>{element}</SentryErrorBoundary>,
        }),
        index: index as false, // needed because index return type is misinterpreted
        children: children !== undefined ? displayRoutes(children) : undefined,
      };
    },
  );
};

export default AppRoutes;
