import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useGetSessionId } from '../hooks/notification/useGetSessionId';

const getWebSocketUrl = (sessionId: string) =>
  `${
    import.meta.env.VITE_WEBSOCKET_API_URL as string
  }?sessionId=${sessionId}&type=application`;

const SOCKET_RECONNECTION_TIMEOUT = 3000;

interface ISocketProvider {
  children: JSX.Element | JSX.Element[];
}

export const WebSocketContext = createContext<{ webSocket?: WebSocket }>({});

export const WebSocketProvider = (props: ISocketProvider): JSX.Element => {
  const sessionId = useGetSessionId();
  const [ws, setWs] = useState<WebSocket>();

  useEffect(() => {
    if (sessionId.data !== undefined) {
      setWs(new WebSocket(getWebSocketUrl(sessionId.data)));
    }
  }, [sessionId.data]);

  useEffect(() => {
    if (ws === undefined) return;
    if (sessionId.data === undefined) return;
    const onClose = () => {
      setTimeout(() => {
        setWs(new WebSocket(getWebSocketUrl(sessionId.data)));
      }, SOCKET_RECONNECTION_TIMEOUT);
    };
    ws.addEventListener('close', onClose);

    return () => {
      ws.removeEventListener('close', onClose);
    };
  }, [ws, sessionId.data]);

  return (
    <WebSocketContext.Provider value={{ webSocket: ws }}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

type OnMessageType<T> = (data: T & { topic: string }) => void;

const formatMessageEvent = <T,>(
  event: MessageEvent,
):
  | (T & {
      topic: string;
    })
  | undefined => {
  const data = JSON.parse(event.data as string) as unknown as T & {
    topic: string;
  };

  return data;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useWebSocket = <T extends unknown>({
  topic,
  onMessage,
}: {
  topic: string[];
  onMessage: OnMessageType<
    T & {
      topic: string;
    }
  >;
}): void => {
  const { webSocket } = useContext(WebSocketContext);

  const handleNewMessage = useCallback(
    (event: MessageEvent<T>) => {
      // when we will better know the type of the message we receive we can add some parsing/type validation here
      const data = formatMessageEvent<T>(event);

      if (data === undefined || !topic.includes(data.topic)) return;

      onMessage(data);
    },
    [onMessage, topic],
  );

  useEffect(() => {
    if (webSocket === undefined) return;
    webSocket.addEventListener('message', handleNewMessage);

    return () => webSocket.removeEventListener('message', handleNewMessage);
  }, [webSocket, handleNewMessage]);
};
