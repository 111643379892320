import { css, keyframes } from '@emotion/react';

import { IToast, spacings, theme, Toast } from '@dialog/design-system';

const animationIn = keyframes`
0% {
  right: -400px;
}

100% {
    right: 0;
}
`;

export interface IToastConfig extends IToast {
  id: string;
  timeout?: number;
  keepOnScreen?: boolean;
  offset?: number;
}

const TOAST_HEIGHT = '100px';
const TOAST_MARGIN = '16px';

export const ToastContainer = ({
  toasts,
  removeToast,
}: {
  toasts: IToastConfig[];
  removeToast: (id: string) => void;
}): JSX.Element | null => {
  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        zIndex: theme.zIndex.highest,
        gap: spacings.lg,
        bottom: spacings.xl,
        right: spacings.xl,
      })}
    >
      {toasts.map(({ id, description, type, action, title, offset }, index) => {
        return (
          <Toast
            title={title}
            action={action}
            key={id}
            customCss={css({
              position: 'absolute',
              overflow: 'hidden',
              maxHeight: TOAST_HEIGHT,
              animation: `${animationIn} 0.33s ease-in-out`,
              animationFillMode: 'forwards',
              bottom: `calc((${TOAST_HEIGHT} + ${TOAST_MARGIN}) * ${index} + ${
                offset ?? 0
              }px)`,
            })}
            description={description}
            type={type}
            onClose={() => removeToast(id)}
          />
        );
      })}
    </div>
  );
};
