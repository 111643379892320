import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  IToastConfig,
  ToastContainer,
} from 'components/shared/ToastContainer/ToastContainer';

interface ToastContextInterface {
  showToast: (
    toast: Omit<IToastConfig, 'id'> & { id?: string; offset?: number },
  ) => string | undefined;
  removeToast: (id: string) => void;
}

export const ToastContext = createContext<ToastContextInterface>({
  showToast: () => {
    console.error('Toast context is not well initialized');

    return undefined;
  },
  removeToast: () => console.error('Toast context is not well initialized'),
});

export const useToasts = (): ToastContextInterface => useContext(ToastContext);

export const ToastProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const [toasts, setToasts] = useState<IToastConfig[]>([]);

  const removeToast = useCallback((id: string) => {
    setToasts(prev => {
      return [...prev.filter(toast => toast.id !== id)];
    });
  }, []);

  const addToast = useCallback(
    (toast: Omit<IToastConfig, 'id'> & { id?: string }) => {
      const defaultId = toast.id;
      const id = defaultId ?? crypto.randomUUID();

      if (toast.keepOnScreen !== true) {
        setTimeout(() => {
          removeToast(id);
        }, toast.timeout ?? 5000);
      }

      setToasts(prev => {
        if (defaultId !== undefined) {
          return [
            ...prev.filter(t => t.id !== toast.id),
            { ...toast, id: defaultId },
          ];
        }

        return [...prev, { ...toast, id }];
      });

      return id;
    },
    [removeToast],
  );

  const toastContext = useMemo(
    () => ({
      showToast: addToast,
      removeToast,
    }),
    [addToast, removeToast],
  );

  return (
    <ToastContext.Provider value={toastContext}>
      <>
        {children}
        {toasts.length > 0
          ? createPortal(
              <ToastContainer toasts={toasts} removeToast={removeToast} />,
              document.body,
            )
          : null}
      </>
    </ToastContext.Provider>
  );
};
