import {
  ArrayOperator,
  CollectionOperator,
  DataType,
  EventOperator,
  Operator,
} from '@dialog/shared-types';

const moneyAndNumberOperators = [
  { value: Operator.EQUALS, isComparison: true },
  { value: Operator.LT, isComparison: true },
  { value: Operator.GT, isComparison: true },
  { value: Operator.LTE, isComparison: true },
  { value: Operator.GTE, isComparison: true },
  { value: Operator.IS_SET, isComparison: false },
  { value: Operator.IS_NOT_SET, isComparison: false },
];

const booleanOperators = [
  { value: Operator.IS_TRUE, isComparison: false },
  { value: Operator.IS_FALSE, isComparison: false },
  { value: Operator.IS_SET, isComparison: false },
  { value: Operator.IS_NOT_SET, isComparison: false },
];

const arrayOperators = [
  {
    value: ArrayOperator.ARRAY_CONTAINS,
    isComparison: true,
    translationKey: 'arrayContains',
  },
  {
    value: ArrayOperator.ARRAY_NOT_CONTAINS,
    isComparison: true,
    translationKey: 'arrayNotContains',
  },
  {
    value: ArrayOperator.ARRAY_NOT_EMPTY,
    isComparison: false,
    translationKey: 'arrayNotEmpty',
  },
  {
    value: ArrayOperator.ARRAY_EMPTY,
    isComparison: false,
    translationKey: 'arrayEmpty',
  },
];

export interface OperatorOption {
  value: Operator | CollectionOperator | ArrayOperator | EventOperator;
  isComparison: boolean;
  translationKey?: string;
  type?: 'number';
  prefix?: string;
}

export const operatorOptions: Record<DataType, OperatorOption[]> = {
  smartLabels: arrayOperators,
  activityScore: moneyAndNumberOperators,
  number: moneyAndNumberOperators,
  money: moneyAndNumberOperators,
  boolean: booleanOperators,
  list: [
    {
      value: CollectionOperator.COLLECTION_BALANCE_EQUALS,
      isComparison: true,
      translationKey: 'balanceEquals',
    },
    {
      value: CollectionOperator.COLLECTION_BALANCE_LT,
      isComparison: true,
      translationKey: 'balanceIsLess',
    },
    {
      value: CollectionOperator.COLLECTION_BALANCE_GT,
      isComparison: true,
      translationKey: 'balanceIsGreater',
    },
    {
      value: CollectionOperator.COLLECTION_BALANCE_LTE,
      isComparison: true,
      translationKey: 'balanceIsLessOrEquals',
    },
    {
      value: CollectionOperator.COLLECTION_BALANCE_GTE,
      isComparison: true,
      translationKey: 'balanceIsGreaterOrEquals',
    },
    {
      value: CollectionOperator.HAS_COLLECTION,
      isComparison: false,
      translationKey: 'has',
    },
    {
      value: CollectionOperator.HAS_NOT_COLLECTION,
      isComparison: false,
      translationKey: 'hasNot',
    },
  ] as {
    value: CollectionOperator;
    isComparison: boolean;
    translationKey?: string;
  }[],
  date: [
    {
      value: Operator.RELATIVE_GT,
      isComparison: true,
      type: 'number',
      prefix: 'daysAgo',
    },
    { value: Operator.ON, isComparison: true },
    {
      value: Operator.RELATIVE_LT,
      isComparison: true,
      type: 'number',
      prefix: 'daysAgo',
    },
    { value: Operator.LTE, isComparison: true, translationKey: 'isBefore' },
    { value: Operator.GTE, isComparison: true, translationKey: 'isAfter' },
    { value: Operator.IS_SET, isComparison: false },
    { value: Operator.IS_NOT_SET, isComparison: false },
  ],
  string: [
    { value: Operator.CONTAINS, isComparison: true },
    { value: Operator.DOES_NOT_CONTAIN, isComparison: true },
    { value: Operator.EQUALS, isComparison: true, translationKey: 'is' },
    { value: Operator.NOT_EQUALS, isComparison: true, translationKey: 'isNot' },
    { value: Operator.IS_SET, isComparison: false },
    { value: Operator.IS_NOT_SET, isComparison: false },
  ],
  event: [
    {
      value: EventOperator.RELATIVE_GT,
      isComparison: true,
      type: 'number',
      prefix: 'daysAgo',
    },
    { value: EventOperator.ON, isComparison: true },
    {
      value: EventOperator.RELATIVE_LT,
      isComparison: true,
      type: 'number',
      prefix: 'daysAgo',
    },
    { value: EventOperator.LTE, isComparison: true, translationKey: 'before' },
    { value: EventOperator.GTE, isComparison: true, translationKey: 'after' },
    {
      value: EventOperator.HAS,
      isComparison: false,
      translationKey: 'hasWithSuffix',
    },
    {
      value: EventOperator.HAS_NOT,
      isComparison: false,
      translationKey: 'hasNotWithSuffix',
    },
  ],
};
