import { createContext, useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';

import { WalkthroughOrganization } from '@dialog/organization-contracts';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { useGetWalkthroughOrganization } from 'hooks/organization';

const checkWalkthroughDone = (
  walkthroughOrganization: WalkthroughOrganization | undefined,
): boolean => {
  if (walkthroughOrganization === undefined) return false;

  return (
    walkthroughOrganization.configureProductQuestions === true &&
    walkthroughOrganization.configureStore === true &&
    walkthroughOrganization.testStore === true
  );
};

interface WalkthroughModalContextInterface {
  modalState: 'hidden' | 'visible';
  walkthroughOrganization: UseQueryResult<WalkthroughOrganization>;
  setModalState: (state: 'hidden' | 'visible') => void;
}

const WalkthroughModalContext = createContext<WalkthroughModalContextInterface>(
  {
    modalState: 'hidden',
    setModalState: () =>
      console.error('WalkthroughModalContext not well initialized'),
    // @ts-expect-error must be defined in provider
    walkthroughOrganization: {},
  },
);

export const useWalkthroughModal = (): WalkthroughModalContextInterface =>
  useContext(WalkthroughModalContext);

export const WalkthroughModalProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { organization } = useOrganization();
  const walkthroughOrganization = useGetWalkthroughOrganization({
    organizationSlug: organization.slug,
    onSuccess: data => {
      const updatedState = checkWalkthroughDone(data) ? 'hidden' : 'visible';
      setCurrentModalState(updatedState);
    },
    onError: error => {
      if (
        error.response?.status !== undefined &&
        error.response.status === 404
      ) {
        setCurrentModalState('visible');

        return;
      }
      setCurrentModalState('hidden');
    },
  });
  const [currentModalState, setCurrentModalState] = useState<
    'hidden' | 'visible'
  >('hidden');

  return (
    <WalkthroughModalContext.Provider
      value={{
        modalState: currentModalState,
        walkthroughOrganization,
        setModalState: setCurrentModalState,
      }}
    >
      {children}
    </WalkthroughModalContext.Provider>
  );
};
