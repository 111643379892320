import { INTERNAL_USER_EMAIL_DOMAIN } from '@dialog/configuration';

export const TRACKING_EVENTS = {
  PAGE_CAMPAIGN_REPORTS: 'page_campaign_reports',
  OPENED_USER_PROFILE: 'opened_user_profile',
};

export const shouldTrackUser = (email: string): boolean => {
  return email.indexOf(INTERNAL_USER_EMAIL_DOMAIN) === -1;
};
