/*eslint max-lines: ["error", 228]*/
import { GridColDef } from '@mui/x-data-grid';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  Segment as SegmentApi,
  SegmentColumn,
} from '@dialog/organization-contracts';
import { RuleGroup } from '@dialog/shared-types';

import { WalletIcon } from 'components/Icons/WalletIcon';
import { RoutePaths } from 'config';
import { useGetSegments } from 'hooks/segments';
import { useQueryParams } from 'hooks/utils/useQueryParams';
import { emptyFilter } from 'services/queryBuilder';

export const ALL_WALLETS_SEGMENT_ID = 'allWallets';
export const getAllWalletsSegment = (): Segment => {
  return {
    translationKey: 'home.all-wallets',
    id: 'allWallets',
    filter: emptyFilter,
    icon: <WalletIcon />,
    order: -1,
  } as unknown as Segment;
};

export interface Segment {
  translationKey?: string;
  id: string;
  filter: RuleGroup;
  name?: string;
  icon?: JSX.Element;
  columns: (GridColDef & { id: string })[];
  order: number;
}

export interface ISegmentData {
  selectedSegment: Segment;
  currentFilters: RuleGroup;
  currentColumns: (GridColDef & { id: string })[];
}
export interface SegmentContextInterface {
  segmentData: ISegmentData;
  allSegments: Segment[];
  changeSegment: (segment: Segment) => void;
  setSegmentData: Dispatch<SetStateAction<ISegmentData>>;
  setSegmentsOrdered: (segments: Segment[]) => void;
  refreshAllWalletsSegmentColumns: (defaultColumns: SegmentColumn[]) => void;
}
const initialAllWalletsSegment = getAllWalletsSegment();
const defaultValueSegmentData = {
  selectedSegment: initialAllWalletsSegment,
  currentFilters: initialAllWalletsSegment.filter,
  currentColumns: initialAllWalletsSegment.columns,
};
export const SegmentContext = createContext<SegmentContextInterface>({
  allSegments: [],
  segmentData: defaultValueSegmentData,
  setSegmentData: () => console.error('Segment data not well intialized'),
  changeSegment: () => console.error('Segment context is not well initialized'),
  setSegmentsOrdered: () =>
    console.error('Segment context is not well initialized'),
  refreshAllWalletsSegmentColumns: () =>
    console.error('Segment context is not well initialized'),
});

export const useSegment = (): SegmentContextInterface =>
  useContext(SegmentContext);

export const orderSegments = (
  segments: SegmentApi[] | undefined,
): SegmentApi[] => {
  if (segments === undefined) return [];

  return segments.sort((segmentA, segmentB) => segmentA.order - segmentB.order);
};

export const SegmentProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const allWalletsSegment = useMemo(() => getAllWalletsSegment(), []);

  const getSegments = useGetSegments({
    refetchOnWindowFocus: false,
    onSuccess: segments => {
      setSegments([allWalletsSegment, ...orderSegments(segments)] as Segment[]);
    },
  });
  const { segmentId, walletAddress } = useQueryParams();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [selectedSegmentId, setSelectedSegmentId] = useState(
    segmentId ?? allWalletsSegment.id,
  );
  const isHomePage = location.pathname === RoutePaths.HOME_PAGE;

  const [segmentData, setSegmentData] = useState<{
    selectedSegment: Segment;
    currentFilters: RuleGroup;
    currentColumns: (GridColDef & { id: string })[];
  }>(defaultValueSegmentData);

  const [segments, setSegments] = useState<Segment[]>([
    allWalletsSegment,
    ...orderSegments(getSegments.data),
  ] as Segment[]);

  const setSegmentsOrdered = useCallback(
    (newSegments: Segment[]) => {
      setSegments([allWalletsSegment, ...newSegments]);
    },
    [setSegments, allWalletsSegment],
  );

  const refreshAllWalletsSegmentColumns = useCallback(() => {
    setSegments([
      getAllWalletsSegment(),
      ...orderSegments(getSegments.data),
    ] as Segment[]);
  }, [getSegments.data]);

  useEffect(() => {
    if (walletAddress === undefined && isHomePage) {
      setSearchParams({
        segmentId: segmentId ?? allWalletsSegment.id,
      });
    }
  }, [
    isHomePage,
    segmentId,
    selectedSegmentId,
    setSearchParams,
    walletAddress,
    allWalletsSegment.id,
  ]);

  const changeSegment = useCallback((segment: Segment) => {
    setSegmentData({
      selectedSegment: segment,
      currentFilters: segment.filter,
      currentColumns: segment.columns,
    });
  }, []);

  useEffect(() => {
    if (segmentId !== undefined) {
      setSelectedSegmentId(segmentId);
    }
  }, [segmentId, setSelectedSegmentId]);

  useEffect(() => {
    if (getSegments.data !== undefined) {
      const selectedSegment =
        segments.find(segment => segment.id === selectedSegmentId) ??
        allWalletsSegment;

      changeSegment(selectedSegment);
    }
  }, [
    getSegments.data,
    segments,
    selectedSegmentId,
    changeSegment,
    allWalletsSegment,
  ]);

  const segmentContext = useMemo(() => {
    return {
      changeSegment: changeSegment,
      segmentData,
      allSegments: segments,
      setSegmentData,
      setSegmentsOrdered,
      refreshAllWalletsSegmentColumns,
    };
  }, [
    changeSegment,
    segmentData,
    segments,
    setSegmentsOrdered,
    refreshAllWalletsSegmentColumns,
  ]);

  return (
    <SegmentContext.Provider value={segmentContext}>
      {children}
    </SegmentContext.Provider>
  );
};
