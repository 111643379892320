import { OnlyLoggedOutRoute } from 'components';

export const WithOnlyLogout = ({
  children,
  onlyLogout,
}: {
  children: JSX.Element | JSX.Element[];
  onlyLogout: boolean;
}): JSX.Element => {
  if (onlyLogout) {
    return (
      <OnlyLoggedOutRoute>
        <>{children}</>
      </OnlyLoggedOutRoute>
    );
  }

  return <>{children}</>;
};
