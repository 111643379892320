import { OrganizationIntegrationNames } from '@dialog/organization-contracts';

export const getGenerateOrganizationKey = (): readonly ['organization'] => {
  return ['organization'];
};

export const getGenerateOrganizationIntegrationKey = ({
  organizationSlug,
  integrationName,
}: {
  organizationSlug: string;
  integrationName: OrganizationIntegrationNames;
}): readonly [
  'organizationIntegration',
  {
    readonly organizationSlug: string;
    readonly integrationName: OrganizationIntegrationNames;
  },
] => {
  return [
    'organizationIntegration',
    {
      organizationSlug,
      integrationName,
    },
  ];
};

export const getGenerateOrganizationIntegrationsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly [
  'organizationIntegrations',
  {
    readonly organizationSlug: string;
  },
] => {
  return [
    'organizationIntegrations',
    {
      organizationSlug,
    },
  ];
};

export const getGenerateMetafieldsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['metafields', { readonly organizationSlug: string }] => {
  return ['metafields', { organizationSlug }];
};

export const getGenerateAiConfigKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['aiConfig', { readonly organizationSlug: string }] => {
  return ['aiConfig', { organizationSlug }];
};

export const getGenerateWidgetSettingsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['widgetSettings', { readonly organizationSlug: string }] => {
  return ['widgetSettings', { organizationSlug }];
};

export const getGenerateWalkthroughsKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['walkthrough', { readonly organizationSlug: string }] => {
  return ['walkthrough', { organizationSlug }];
};

export const getBillingDataKey = ({
  organizationSlug,
}: {
  organizationSlug: string;
}): readonly ['billingData', { readonly organizationSlug: string }] => {
  return ['billingData', { organizationSlug }];
};
