/* eslint-disable max-lines */
export const ErrorImage = (): JSX.Element => {
  return (
    <svg
      width="260"
      height="160"
      viewBox="0 0 260 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_73)">
        <rect width="260" height="160" rx="24" fill="#FCFCFC" />
        <g filter="url(#filter0_f_1_73)">
          <circle cx="130.5" cy="250" r="170" fill="#DBD6FE" />
        </g>
        <rect
          x="73"
          y="31"
          width="111"
          height="71"
          rx="8"
          fill="url(#paint0_linear_1_73)"
        />
        <g filter="url(#filter1_dddddd_1_73)">
          <rect
            x="28"
            y="22"
            width="204"
            height="116"
            rx="12"
            fill="url(#paint1_linear_1_73)"
          />
          <g clipPath="url(#clip1_1_73)">
            <g filter="url(#filter2_d_1_73)">
              <path
                d="M99.9693 96.5163L125.672 58.04C127.093 55.9136 127.803 54.8503 128.73 54.4932C129.538 54.1817 130.461 54.1817 131.269 54.4932C132.196 54.8503 132.906 55.9136 134.326 58.04L160.029 96.5163C161.455 98.6504 162.168 99.7175 162.062 100.593C161.97 101.357 161.509 102.051 160.792 102.503C159.97 103.021 158.547 103.021 155.702 103.021H104.296C101.451 103.021 100.028 103.021 99.2066 102.503C98.4899 102.051 98.0281 101.357 97.9362 100.593C97.8309 99.7175 98.5437 98.6504 99.9693 96.5163Z"
                fill="#7B7BCF"
              />
              <path
                d="M129.999 71.875V82.7083M129.999 93.5416H130.03M125.672 58.04L99.9693 96.5163C98.5437 98.6504 97.8309 99.7175 97.9362 100.593C98.0281 101.357 98.4899 102.051 99.2066 102.503C100.028 103.021 101.451 103.021 104.296 103.021H155.702C158.547 103.021 159.97 103.021 160.792 102.503C161.509 102.051 161.97 101.357 162.062 100.593C162.168 99.7175 161.455 98.6504 160.029 96.5163L134.326 58.04C132.906 55.9136 132.196 54.8503 131.269 54.4932C130.461 54.1817 129.538 54.1817 128.73 54.4932C127.803 54.8503 127.093 55.9136 125.672 58.04Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_1_73"
          x="-219.5"
          y="-100"
          width="700"
          height="700"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="90"
            result="effect1_foregroundBlur_1_73"
          />
        </filter>
        <filter
          id="filter1_dddddd_1_73"
          x="8"
          y="22"
          width="244"
          height="169"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.913195" />
          <feGaussianBlur stdDeviation="0.276726" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.01 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_73"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.19453" />
          <feGaussianBlur stdDeviation="0.66501" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.01 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1_73"
            result="effect2_dropShadow_1_73"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.13211" />
          <feGaussianBlur stdDeviation="1.25216" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.01 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_1_73"
            result="effect3_dropShadow_1_73"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.37098" />
          <feGaussianBlur stdDeviation="2.23363" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.0119221 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_1_73"
            result="effect4_dropShadow_1_73"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.7866" />
          <feGaussianBlur stdDeviation="4.17776" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.0143771 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_dropShadow_1_73"
            result="effect5_dropShadow_1_73"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="33" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.145098 0 0 0 0 0.133333 0 0 0 0 0.427451 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="effect5_dropShadow_1_73"
            result="effect6_dropShadow_1_73"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect6_dropShadow_1_73"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1_73"
          x="92.926"
          y="53.2596"
          width="74.1464"
          height="58.7612"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_73"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_73"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_73"
          x1="128.5"
          y1="31"
          x2="128.5"
          y2="102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4F2FA" />
          <stop offset="1" stopColor="#EDEAF5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_73"
          x1="130"
          y1="22"
          x2="130"
          y2="138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.6" />
        </linearGradient>
        <clipPath id="clip0_1_73">
          <rect width="260" height="160" rx="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1_73">
          <rect
            width="75"
            height="65"
            fill="white"
            transform="translate(92.5 47.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
