import { useQuery, UseQueryResult } from 'react-query';

import { Segment } from '@dialog/organization-contracts';
import { Trait } from '@dialog/shared-types';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { fetchSegments } from 'services/fetch';
import { useFlattenedTraits } from 'services/trait';

export const useGenerateSegmentsKey = (): readonly [
  'segments',
  {
    readonly organizationSlug: string;
    readonly flattenedTraits: Trait[];
  },
] => {
  const { organization } = useOrganization();
  const flattenedTraits = useFlattenedTraits(organization);

  return ['segments', { organizationSlug: organization.slug, flattenedTraits }];
};

export const useGetSegments = (
  {
    enabled,
    refetchOnWindowFocus,
    onSuccess,
  }: {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
    onSuccess?: (segments: Segment[]) => void;
  } = { enabled: true, refetchOnWindowFocus: true },
): UseQueryResult<Segment[]> => {
  const key = useGenerateSegmentsKey();

  return useQuery(key, fetchSegments, {
    enabled,
    refetchOnWindowFocus,
    onSuccess,
  });
};
