import { useQuery, UseQueryResult } from 'react-query';

import { WidgetSettings } from '@dialog/search-contracts';

import { getWidgetSettings } from 'services/fetch';

import { getGenerateWidgetSettingsKey } from './keys';

export const useGetWidgetSettings = ({
  organizationSlug,
  onSuccess,
  onError,
}: {
  organizationSlug: string;
  onSuccess?: (data: WidgetSettings) => void;
  onError?: () => void;
}): UseQueryResult<WidgetSettings> => {
  const key = getGenerateWidgetSettingsKey({ organizationSlug });

  return useQuery(key, async () => getWidgetSettings({ organizationSlug }), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
};
