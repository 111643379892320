import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  SensorDescriptor,
  SensorOptions,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { DraggableItem } from './DraggableItem';

export const DraggableList = <T extends { id: string }>({
  customSensors,
  items,
  onUpdate,
  children,
}: {
  customSensors?: SensorDescriptor<SensorOptions>[];
  onUpdate: (items: T[]) => void;
  items: T[];
  children?: JSX.Element[];
}): JSX.Element => {
  const [draggableItems, setDraggableItems] = useState<T[]>(items);
  const [draggingItem, setDraggingItem] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setDraggableItems(items);
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    const previousIndex = draggableItems.findIndex(
      item => item.id === active.id,
    );
    const newIndex = draggableItems.findIndex(item => item.id === over?.id);

    const orderedItems = arrayMove(draggableItems, previousIndex, newIndex);
    setDraggableItems(orderedItems);
    onUpdate(orderedItems);
  };

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      sensors={customSensors !== undefined ? customSensors : sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={(event: DragStartEvent) => {
        setDraggingItem(event.active.id as string);
      }}
    >
      <SortableContext
        items={draggableItems}
        strategy={verticalListSortingStrategy}
      >
        {children?.map(child => (
          <DraggableItem
            key={`draggable-item-${child.key ?? ''}`}
            id={child.key ?? ''}
            isDragged={child.key === draggingItem}
          >
            {child}
          </DraggableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};
