import { useQuery, UseQueryResult } from 'react-query';

import { ProductPageQuestionsList } from '@dialog/search-contracts';

import { getProductPageQuestions } from '../../services/fetch/products';

export const getProductPageQuestionsKey = ({
  locale,
}: {
  locale?: string;
}): readonly [
  'product-page-questions',
  {
    readonly locale?: string;
  },
] => {
  return ['product-page-questions', { locale }];
};

export interface IUseGetProductPageQuestions {
  locale?: string;
  organizationSlug: string;
  onSuccess?: (data: ProductPageQuestionsList) => void;
  onError?: () => void;
}

export type IUseGetProductPageQuestionsResponse =
  UseQueryResult<ProductPageQuestionsList>;
export const useGetProductPageQuestions = ({
  locale,
  organizationSlug,
  onSuccess,
  onError,
}: IUseGetProductPageQuestions): IUseGetProductPageQuestionsResponse => {
  const key = getProductPageQuestionsKey({
    locale,
  });

  return useQuery(
    key,
    async () =>
      getProductPageQuestions({
        locale,
        organizationSlug,
      }),
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
    },
  );
};
