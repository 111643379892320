/* eslint max-lines: ["error", 300] */
import { css } from '@emotion/react';
import { useFlagsmith } from 'flagsmith/react';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Button,
  DialogIconBlank,
  getSpacing,
  InfoCard,
  Input,
  InputFile,
  ITab,
  PageLoader,
  spacings,
  Tabs,
  theme,
  Typography,
} from '@dialog/design-system';
import { OrganizationPlans } from '@dialog/organization-contracts';
import { WidgetSettings as WidgetSettingsType } from '@dialog/search-contracts';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { useToasts } from 'context/ToastProvider';
import { useGetWidgetSettings } from 'hooks/organization/useGetWidgetSettings';
import { useUpdateWidgetSettings } from 'hooks/organization/useUpdateWidgetSettings';
import { handleFileRead, isUnderFileLimitSize } from 'utils/files';

const BOOKMARK_LOGO_FLAG = 'display_bookmark_logo';

export const WidgetSettings = (): JSX.Element => {
  const intl = useIntl();
  const { organization } = useOrganization();
  const inputDisabled = organization.plan === OrganizationPlans.FREE;
  const { showToast } = useToasts();
  const { handleSubmit, getValues, reset, control, setValue } =
    useForm<WidgetSettingsType>();
  const settingsQuery = useGetWidgetSettings({
    organizationSlug: organization.slug,
    onSuccess: (data: WidgetSettingsType) => {
      reset(data);
    },
  });

  const flagsmith = useFlagsmith();

  const updateSettings = useUpdateWidgetSettings();

  const locales = useMemo(() => organization.shopLocales ?? [], [organization]);

  const handleFileUpload = async (event: React.SyntheticEvent) => {
    const data = await handleFileRead(event);

    if (!isUnderFileLimitSize(data?.file.size)) {
      showToast({
        type: 'error',
        description: intl.formatMessage({
          id: 'hooks.shared.fileUploadDescriptionSizeError',
        }),
      });

      return;
    }

    const target = event.target as HTMLInputElement;
    target.value = '';
    if (data?.file.type.match(/(png|jpeg|jpg)/)) {
      setValue('bookmarkLogoUrl', data.readableByImg);
    }
  };

  const onSubmit = async () => {
    const formValues = getValues();
    await updateSettings.mutateAsync(formValues);
  };

  const assistantNameTabs: ITab[] = useMemo(
    () =>
      locales.map(locale => ({
        id: locale.locale,
        title: locale.name,
        view: (
          <Controller
            control={control}
            name={`assistantNameTranslations.${locale.locale}`}
            render={({ field }) => (
              <Input disabled={inputDisabled} {...field} />
            )}
          />
        ),
      })),
    [locales, inputDisabled, control],
  );

  const assistantDescriptionTabs: ITab[] = useMemo(
    () =>
      locales.map(locale => ({
        id: locale.locale,
        title: locale.name,
        view: (
          <Controller
            render={({ field }) => (
              <Input disabled={inputDisabled} {...field} />
            )}
            control={control}
            name={`assistantDescriptionTranslations.${locale.locale}`}
          />
        ),
      })),
    [locales, inputDisabled, control],
  );

  const dialogInputPlaceholderTabs: ITab[] = useMemo(
    () =>
      locales.map(locale => ({
        id: locale.locale,
        title: locale.name,
        view: (
          <Controller
            render={({ field }) => (
              <Input disabled={inputDisabled} {...field} />
            )}
            control={control}
            name={`dialogInputPlaceholderTranslations.${locale.locale}`}
          />
        ),
      })),
    [locales, inputDisabled, control],
  );

  if (settingsQuery.isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      css={css({
        padding: spacings.xl,
        display: 'flex',
        flexDirection: 'column',
        gap: spacings.lg,
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          css={css({
            display: 'flex',
            flexDirection: 'column',
            gap: spacings.sm,

            '&::after': {
              content: "''",
              width: '100%',
              height: '1px',
              marginTop: getSpacing(4),
              marginBottom: getSpacing(4),
              background: theme.palette.grey100,
            },
          })}
        >
          <Typography variant="xxl">
            <FormattedMessage id="settings.widget-settings.title" />
          </Typography>

          <Typography variant="sm-reg">
            <FormattedMessage id="settings.widget-settings.description" />
          </Typography>
        </div>
        <div
          css={css({
            display: 'flex',
            flexDirection: 'column',
            gap: spacings.xxl,
          })}
        >
          {organization.plan === OrganizationPlans.FREE && (
            <InfoCard
              type="warning"
              title={intl.formatMessage({
                id: 'settings.widget-settings.warning',
              })}
              description={intl.formatMessage({
                id: 'settings.widget-settings.warningDescription',
              })}
            />
          )}
          <Typography variant="base-md">
            <FormattedMessage id="settings.widget-settings.assistantName" />
          </Typography>
          <Tabs tabs={assistantNameTabs} />
          <Typography variant="base-md">
            <FormattedMessage id="settings.widget-settings.assistantDescription" />
          </Typography>
          <Tabs tabs={assistantDescriptionTabs} />
          <Typography variant="base-md">
            <FormattedMessage id="settings.widget-settings.dialogInputPlaceholder" />
          </Typography>
          <Tabs tabs={dialogInputPlaceholderTabs} />

          {flagsmith.hasFeature(BOOKMARK_LOGO_FLAG) ? (
            <>
              <div
                css={css({
                  display: 'flex',
                  gap: spacings.xs,
                  flexDirection: 'column',
                })}
              >
                <Typography variant="base-md">
                  <FormattedMessage id="settings.widget-settings.bookmarkLogo" />
                </Typography>
                <Typography variant="xs-reg" color={theme.palette.grey600}>
                  <FormattedMessage id="settings.widget-settings.maxBookmarkSize" />
                </Typography>
              </div>
              <div
                css={css({
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  gap: spacings.lg,
                })}
              >
                <div
                  css={css({
                    borderTopLeftRadius: theme.borderRadius.xl,
                    borderBottomLeftRadius: theme.borderRadius.xl,
                    boxShadow: theme.shadow.lg,
                    border: '1px solid rgb(237 237 241)',
                    width: '64px',
                    maxHeight: '64px',
                    padding: `${spacings.sm} ${spacings.lg}`,
                  })}
                >
                  {getValues().bookmarkLogoUrl === undefined ? (
                    <DialogIconBlank />
                  ) : (
                    <img
                      css={css({
                        width: '36px',
                        height: '36px',
                        borderRadius: theme.borderRadius.md,
                      })}
                      src={getValues().bookmarkLogoUrl}
                      alt="organization-logo"
                    />
                  )}
                </div>
                <Controller
                  control={control}
                  name="bookmarkLogoUrl"
                  render={({ field }) => (
                    <InputFile
                      disabled={inputDisabled}
                      id="bookmarkLogoUrl"
                      label={intl.formatMessage({
                        id: 'settings.widget-settings.uploadLogo',
                      })}
                      accept=".png, .jpg, .jpeg"
                      {...field}
                      onChange={handleFileUpload}
                    />
                  )}
                />
              </div>
            </>
          ) : null}
          <div
            css={css({
              display: 'flex',
              justifyContent: 'flex-end',
            })}
          >
            <Button
              disabled={inputDisabled}
              type="submit"
              loading={updateSettings.isLoading}
            >
              <FormattedMessage id="settings.widget-settings.save" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WidgetSettings;
