import { useAuth } from 'components/AuthProvider/AuthProvider';

const OnlyLoggedOutRoute = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const authContext = useAuth();

  if (authContext.session !== undefined) {
    location.replace('/');
  }

  return children;
};

export default OnlyLoggedOutRoute;
