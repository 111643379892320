/* eslint-disable max-lines */
export const enUSMessages = {
  'user-not-found': 'User not found',
  'unknown-error': 'Something unexpected happened',
  'invalid-email': 'This is not a valid email',
  'invalid-name': 'Name should not be blank',
  'invalid-position': 'Position should not be blank',
  '==': 'equals to',
  daysAgo: 'days ago',
  contains: 'contains',
  doesNotContain: 'does not contain',
  '>': 'is greater than',
  'REL>': 'more than',
  'REL<': 'less than',
  '>=': 'is greater or equals',
  isTrue: 'is true',
  isFalse: 'is false',
  on: 'on',
  isSet: 'is set',
  is: 'is',
  isNot: 'is not',
  isNotSet: 'is not set',
  '<': 'is less than',
  '<=': 'is lesser or equals',
  Wallets: 'wallets',
  before: 'before',
  isBefore: 'is before',
  after: 'after',
  isAfter: 'is after',
  balanceIsGreater: 'balance is greater',
  balanceGT: 'balance is greater',
  balanceIsGreaterOrEquals: 'balance is greater or equals',
  balanceGTE: 'balance is greater or equals',
  balanceIsLessOrEquals: 'balance is less or equals',
  balanceLTE: 'balance is less or equals',
  balanceIsLess: 'balance is less than',
  balanceLT: 'balance is less than',
  balanceEquals: 'balance equals',
  arrayContains: 'contains',
  arrayNotContains: 'does not contain',
  arrayNotEmpty: 'has some',
  arrayEmpty: 'has none',
  hasWithSuffix: 'has {suffix}',
  hasNotWithSuffix: 'has not {suffix}',
  has: 'has any',
  hasNot: 'has not any',
  hasCollection: 'has any',
  wallets: 'wallets',
  back: 'Back',
  UsernameExistsException: 'Email already exists',
  invalidAddress: 'Please enter a valid contract address',
  searchAttributes: 'Search attributes',
  quantity: 'quantity',
  contactSupport: 'Contact support',
  segment: 'Segments',
  copy: 'Copy',
  copied: 'Copied!',
  poweredBy: 'Powered by',
  importProgress: {
    progress: 'Progress of your product catalog import',
  },
  navbar: {
    search: 'Search address',
    labelUserSettings: 'User settings',
    logOut: 'Log out',
    workspaceSettings: 'Organization settings',
    dialogGuide: 'Dialog guide',
    news: "What's new",
  },
  walkthrough: {
    title: "Let's get started",
    leaveMessage: "I'll do this later",
    configureStore: 'Configure your store',
    configureStoreDescription:
      'Now that your product questions are sets, you need to add Dialog to your {Shopify} website',
    testYourPage: 'Test your page in live',
    testYourPageDescription:
      'It’s time to see if it works, navigate to a product page and you should be able to see your product questions',
    checkWebsite: 'Check my website',
    configureProductQuestionTitle: 'Configure your product questions',
    configureProductQuestionDescription:
      'We’ve created some pre-configured questions for you, give it a look, modify or create new one if needed',
  },
  segments: {
    newSegment: 'New Segment',
    createPopup: {
      title: 'Save new segment',
      description:
        'The segment allow you to access to a list of customers based on filters',
      cancelButton: 'Cancel',
      applyButton: 'Create',
      inputPlaceholder: 'Name...',
    },
    updatePopup: {
      title: 'Rename segment',
      description: 'Change the name of the selected segment',
      cancelButton: 'Cancel',
      applyButton: 'Update',
      inputPlaceholder: 'Name...',
    },
    deletePopup: {
      title: 'Delete this segment?',
      description:
        'This segment will no longer be visible and cannot be recovered, are you sure?',
      cancelButton: 'Cancel',
      applyButton: 'Delete',
    },
    planLimit: {
      title: 'Free wallets used',
      description:
        'Your team has created {percentage}% of your available wallets. Need more ?',
      cta: 'Contact us',
      contacted: 'Contacted',
      limitReached: {
        title: 'All free wallets used',
        description:
          'Your team has created all of your available wallets. Need more ?',
      },
    },
  },
  userProfile: {
    save: 'Save',
    newEmail: 'New email',
    name: 'Name',
    profile: 'Profile',
    email: 'Email',
    changeEmail: 'Change email',
    changeEmailError: 'You cannot change your email yet',
  },
  columns: {
    title: 'Columns',
    add: 'Add column',
    apply: 'Apply',
    listingDescription: 'Add or remove columns from your list',
    searchTokenPlaceholder: 'Search token...',
    emptyListDataTitle: 'No results found',
    emptyListDataDescription:
      'We have not found any results matching your search',
    emptyListDataCTA: 'Clear search',
    idleListDataTitle: 'Empty search',
    idleListDataDescription: 'Search something to find some results',
  },
  menu: {
    profile: 'Your profile',
    logOut: 'Log out',
  },
  settings: {
    title: 'Settings',
    changeName: 'You can not change the name of your organization yet',
    organization: 'Organization',
    integrations: {
      title: 'Integrations',
      description: 'Connect your existing tools with Dialog.',
      enabled: 'Enabled',
      comingSoon: 'Coming soon',
      cards: {
        segmentTwilio: {
          title: 'Segment (destination)',
          description: 'Send on-chain insights from Dialog into Segment',
          details: {
            configuration: 'Configuration',
            description: 'Description',
            descriptionValue:
              'Send Dialog data to Segment to enrich your web2 tools.',
            inputPlaceholder: 'Enter your API key',
            writeKey: 'Write Key',
            enabled: 'Enabled',
            descriptionKey:
              "Segment's access key to send Dialog data to Segment",
            enableDestination: 'Enable destination',
            disableDestination: 'Disable destination',
            supportTitle: 'Need help',
            supportDescription:
              'You can follow a step by step guide to install Segment',
            supportCta: 'See documentation',
            integrationType: 'Integration type',
            destination: 'Destination',
            dataOut: 'Data out',
            segments: 'Segments',
            documentation: 'Documentation',
          },
          disableModal: {
            title: 'Disable this integration',
            description:
              'This integration will be immediately stop working, no data will be sent to Segment anymore.',
          },
        },
        empty: {
          content: 'More integration soon',
        },
      },
      support: {
        title: 'Propose a new integration',
        description:
          'We are constantly looking for integrating with external services to help you grow your users.',
      },
    },
    'widget-settings': {
      title: 'Dialog widget settings',
      poweredBy: 'Powered by Dialog',
      bookmarkLogo: 'Your bookmark logo',
      maxBookmarkSize:
        'Your image must be less than 5MB. Accepted formats: PNG, JPG, JPEG',
      uploadLogo: 'Upload your logo',
      displayPoweredBy: 'Display powered by Dialog?',
      description: 'Customize the look and feel of your Dialog widget',
      save: 'Save changes',
      warning: 'You cannot modify your widget settings in the free plan',
      assistantName: 'Your assistant name',
      dialogInputPlaceholder: 'Dialog input placeholder',
      assistantDescription: 'Your assistant description',
      warningDescription:
        'Upgrade to a paid plan to modify your widget settings',
    },
    'ai-expert': {
      title: 'AI expert setup',
      description: 'Customize your AI expert',
      business: 'Describe your business as clearly and concisely as possible',
      expertise: 'Enter the area of expertise your AI needs to master',
      save: 'Save changes',
      warning: 'You cannot upload more than 2 files',
      warningDescription: 'Upgrade to a paid plan to upload more files',
      uploadFiles:
        'Upload additional files that would add knowledge to your assistant',
    },
    usage: {
      usage: {
        title: 'Usage',
        description: 'Current usage informations',
        progressBarDescription:
          '{currentValue} of {maxValue} conversations used',
        additionalProgressBarDescription:
          '{currentValue} additional conversations used ({value}$)',
      },
      title: 'Plan & Usage',
      description: 'Plan & Usage informations',
      plan: 'Plan',
      plans: {
        upgrade: 'Upgrade plan',
        change: 'Change plan',
        free: 'Free',
        scale: 'Scale',
        enterprise: 'Enterprise',
        features: {
          includedConversation: '{value} included conversations per month',
          pricePerAdditionalConversation:
            '{value}$ per additional conversation',
          pricePerAdditionalConversationBasic: 'Blocked',
          aiModelBasic: 'Basic',
          aiModelAdvanced: 'Advanced',
          aiModelEnterprise: 'Advanced or Custom',
          uploadDocuments: '{value} upload of data sources documents',
          customizeTriggers: 'Customize triggers',
          accessHistory: 'Access to conversations historic',
          customerSuccessBasic: 'Emails and live chat',
          customerSuccessAdvanced: 'Emails, live chat and on-call',
          customerSuccessEnterprise: 'Dedicated slack channel',
          customLogo: 'Custom logo',
          customWording: 'Custom wording',
          customDataSource: 'Custom data sources',
          customConversation: 'Customize conversation starter',
          analyticsReports: 'Analytics reports',
          customCode: 'Custom code to personnalize your AI',
        },
      },
    },
    members: {
      removeMember: 'Remove from organization',
      title: 'Members',
      description: 'Manage your team members',
      inviteButtonLabel: 'Invite member',
      searchbarPlaceholder: 'Search by name...',
      inviteModal: {
        inputPlaceholder: 'Email...',
        title: 'Invite members',
        description: 'Invite your team members to join your organization',
        applyButton: 'Invite',
        cancelButton: 'Cancel',
        invalidEmail: 'Enter a valid email',
      },
      removeMemberModal: {
        title: 'Remove {name} from the organization?',
        description:
          "This user will no longer have access to your organization and it's content",
        applyButton: 'Remove',
        cancelButton: 'Cancel',
      },
    },
    general: {
      title: 'General',
      description: "Manage your organization's informations",
      inputOrganizationName: "Organization's name",
      inputAvatarName: "Organization's picture",
      inputAvatarDescription:
        "Your organization's image will be visible in app messages",
      inputAvatarButton: 'Upload image (png, jpg, jpeg, Max 3MB)',
      inputCountryName: 'Country',
      buttonSave: 'Save changes',
      inputOrganizationDomainName: 'Domain name of your organization',
      supportTitle: 'Contact us to change your organization name',
      supportDescription:
        'You are not allowed to change your organization name for security reasons. Please contact our support team if you want to change your organization name.',
    },
    setup: {
      title: 'Setup',
      description:
        'Setup Dialog on your platform to identify connected wallets on your website',
      installScript:
        'Install the following code to start collecting wallet addresses',
      publicApiKey: 'Public API key',
      privateApiKey: 'Private API key',
    },
    profile: {
      title: 'Profile',
      description: 'Manage your personal information',
      firstName: 'First name',
      lastName: 'Last name',
      position: 'Position',
      positionDescription: 'Users will see your position in app messages',
      picture: 'Picture',
      lastNameError: 'Enter a last name',
      firstNameError: 'Enter a first name',
      inputAvatarButton: 'Upload image (png, jpg, jpeg, Max 3MB)',
      avatarDescription: 'Users will see your avatar image in app messages',
      email: 'Email',
      supportTitle: 'Contact us to change your email',
      supportDescription:
        'You are not allowed to change your email for security reasons. Please contact our support team if you want to change your email address.',
      save: 'Save changes',
    },
    name: 'Name',
    account: 'Account',
    installCode: 'Install code',
    publicApiKey: 'Public Api Key',
    privateApiKey: 'Private Api Key',
    install:
      'Install the following code to collect wallet addresses when users connect to your site',
  },
  filters: {
    andCap: 'AND',
    orCap: 'OR',
    customSegment: 'You will soon be able to create custom segments',
    isAfter: 'is after',
    isBefore: 'is before',
    lesserThan: 'is less than',
    greaterThan: 'is greater than',
    equals: 'equals',
    showWalletsMatching: 'Show wallets matching',
    concernedChain: 'Concerned chain(s)',
    allChains: 'All chains',
    allConditions: 'All filters',
    anyCondition: 'Any filters',
    addGroup: 'Add group filter',
    add: 'Add filter',
    edit: 'Edit filter',
    apply: 'Apply',
    title: 'Filters',
    filterCountWallets: 'Showing {count} wallets',
    searchNfts: 'Search for collection or type address...',
    searchTokens: 'Search for token or type address...',
    noResultsTitle: {
      nftCollections: 'None of your users have this collection yet',
      tokens: 'None of your users have this token yet',
    },
    noResultsSubtitle: {
      nftCollections:
        'Search using the collection smart contract to display unlisted collections',
      tokens:
        'Search using the token smart contract to display unlisted tokens',
    },
    rootLevelOnly:
      'This filter can only be used at root level, not in sub-groups',
    disabled: 'You cannot select this filter',
    campaignSelect: 'Select campaign...',
    'event.CAMPAIGN_SENT.suffix': 'been sent',
    'event.CAMPAIGN_SEEN.suffix': 'viewed',
    'event.CAMPAIGN_CLICKED.suffix': 'clicked',
  },
  configureShopping: {
    title: 'Configure your Shopify',
    description: 'Import your products and inventory',
    product: 'Product',
    inventory: 'Inventory',
    informationContainer: {
      titleProduct: 'CSV Product template',
      descriptionProduct:
        'To ensure successful import, it is important that your products.csv file follows Product Shopify template',
      descriptionUploadArea: 'Up to 5MB, .CSV format',
      titleInventory: 'CSV Inventory template',
      descriptionInventory:
        'To ensure successful import, it is important that your inventory.csv file follows Product Shopify template',
      downloadButton: 'Download template',
    },
    successMessage: '{value} has been correctly imported!',
    failedMessage:
      '{value} has not been correctly imported! Drop again your document.',
  },
  assistant: {
    title: 'Assistant POC',
    description: '',
  },
  productQuestions: {
    banners: {
      actions: {
        generate: 'Generating',
        translate: 'Translating',
      },
      generateTranslate: '{action} product page questions ',
    },
    title: 'Product questions configuration',
    default: ' (default)',
    description:
      '{count} {count, plural, =0{product} one {product} other {products}}',
    generationQuestionButtonTooltip: 'Generate a question',
    actionsButtons: {
      translateFromDefaultLanguage: 'Translate from default language',
      generateQuestions: 'Generate questions',
      duplicate: 'Duplicate',
      save: 'Save',
    },
    apiKeyModal: {
      title: 'Welcome on board! 🎉',
      info: 'You can also find your API Key in settings',
      applyButton: 'Done!',
      description:
        'Now that you have complete onboarding, we advise you to copy and keep your API key separetly.',
    },
    table: {
      headers: {
        productName: 'Product name',
        productId: 'Product Id',
        question1: 'Question 1',
        question2: 'Question 2',
        question3: 'Question 3',
      },
    },
    actionsModals: {
      productQuestionsSaveModalTitle: 'Saving product questions',
      productQuestionsSaveModalDescription:
        'Are you sure you want to save {count} {count, plural, =0{product question} one {product question} other {product questions}}?',
      productQuestionsGenerateQuestionsModalTitle: 'Generate questions',
      productQuestionsGenerateQuestionsModalDescription:
        'Are you sure you want to generate with AI questions for {count} {count, plural, =0{product} one {product} other {products}}?',
      productQuestionsTranslateQuestionsModalTitle:
        'Translate questions from default language',
      productQuestionsTranslateQuestionsModalDescription:
        'Are you sure you want to translate from your default language {language} questions for {count} {count, plural, =0{product} one {product} other {products}}?',
    },
  },
  home: {
    table: {
      additionalInformationsColumns: {
        transactionActivityScore:
          'The activity score of a wallet reflects the number and recency of its on-chain transactions, with higher scores indicating more activity. A score of 0 may indicate a new or infrequently used wallet, while a score of 100 may belong to a frequent trader.',
        walletNftValueDollar:
          'Value of NFTs based on floor price. Collections that have very little activity are deemed to be valueless.',
      },
    },
    banners: {
      deleteBanners: {
        description: 'Currently deleting {count} wallets',
        tooltip: 'This wallet is currently being deleted',
      },
      inProgress: 'In progress',
    },
    title: 'Home',
    'crypto-whales': '🐳 Crypto whales',
    'nft-holders': '🖼 NFT holders',
    'recently-active': '📈 Recently active',
    'all-wallets': 'All wallets',
    'sort-by': 'Sort by',
    columns: 'Columns',
    noWallet: "You don't have any wallet yet",
    installCode: 'Install code',
    filters: 'Filters',
    segmentOptions: {
      rename: 'Rename segment',
      importCsv: 'Import CSV file',
      exportAsCsv: 'Export current view to csv',
      delete: 'Delete segment',
      deleteWallets: 'Delete all current wallets',
    },
    cancelModal: {
      title: 'Leave without saving?',
      description: 'When leaving the page you will lose your unsaved changes.',
      cancelButton: 'Stay',
      applyButton: 'Leave anyway',
    },
    saveSegment: 'Save segment',
    saveAsSegment: 'Save as a new segment',
    saveColumns: {
      button: 'Save columns',
      tooltip:
        'These columns will be set as your default columns for all new segments',
    },
    addSegment: 'New segment',
    saveSegmentModal: 'Save',
    updateSegmentModal: 'Update',
    segmentModalTitle: 'Name your segment',
    import: 'Import wallets',
    export: 'Export wallets',
    noWalletFound: {
      noMatchingFilterTitle:
        'We have not found any wallet matching your filters',
      noMatchingFilterDescription:
        'It seems that currently no wallet matches your filters, refine your filters to select a group of wallets',
      noMatchingFilterCta: 'Reset filters',
      noWalletTitle: "We haven't found any wallet",
      noWalletDescription:
        "Setup the Dialog code to retrieve your customer's addresses when they connected their wallet",
      noWalletCta: 'Install code',
    },
  },
  'sign-up': {
    success: 'You successfully signed up!',
  },
  auth: {
    agreementMessage: 'I agree to the {terms} and {privacy}',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    login: 'Log in',
    signUp: 'Sign up',
    continue: 'Continue',
  },
  login: {
    magicLinkTitle: 'Magic Link sent',
    magicLinkDescription:
      'Check your inbox, we’ve sent you a magic login link at {email}',
    backToLoginView: 'Back to log in',
    emailError: 'Enter a valid email address',
    noAccount: "Don't have an account? {signUp}",
    cta: 'Log in',
    emailPlaceholder: 'Enter your email...',
    title: 'Welcome to Dialog',
    description:
      'Enter the email linked to your Shopify Account and we’ll send you a magic log in link',
    'sign-up': 'Sign up',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    position: 'Position',
    'login-success':
      'Check your emails and click on your magic link to authenticate',
    email: 'Email',
    'no-account': 'No account yet?',
  },
  onboardingOrganizationInvitation: {
    title: 'You have been invited to join {organizationName} on Dialog',
    description: 'We need to know a fee things to set up your account',
    email: 'Email',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    firstNameError: 'First name is a required field',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    lastNameError: 'Last name is a required field',
    alreadyAccount: 'Already have an account? {login}',
    login: 'Log in',
    cta: 'Sign up',
  },
  signUp: {
    title: 'Sign up',
    description: 'We need to know a fee things to set up your account',
    backToLoginView: 'Back to log in',
    magicLinkTitle: 'Magic Link sent',
    magicLinkDescription:
      'Check your inbox, we’ve sent you a magic login link at {email}',
    emailPlaceholder: 'Enter your email...',
    email: 'Email',
    emailError: 'Enter a valid email address',
    cta: 'Sign up',
    alreadyAccount: 'Already have an account? {signUp}',
    login: 'Log in',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    firstNameError: 'First name is a required field',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter your last name',
    lastNameError: 'Last name is a required field',
    position: 'Position',
    positionPlaceholder: 'Enter your position',
    positionError: 'Position is a required field',
  },
  onboarding: {
    step: 'Step {currentPage} {maxPage}',
    previous: 'Previous',
    maxPage: 'of {maxPage}',
    next: 'Next',
    choosePlan: {
      title: 'You did not choose a billing plan yet.',
      content:
        'Choose your plan in your Shopify admin in the {dialogApplicationLink}',
    },
    getStartedStep: {
      optional: '*optional',
      getStarted: 'Get started',
      title: "Welcome to Dialog, let's set up your AI expert",
      description:
        'Here are the steps to configure your AI expert in just a few minutes.',
      category: 'Get started',
      configureMetaFields: 'Configure metafields',
      yourBusiness: 'Describe your business',
      areaOfExpertise: 'Your AI area of expertise',
      documentUpload: 'Documents upload',
      setUpWorkspace: 'Set up your workspace',
    },
    configureMetafieldsStep: {
      title: 'Please select required metafields',
      description:
        'Select the product metafields that will help your AI assistant better understand the products your store sells. Avoid including the same information twice (for instance the global.title_tag metafield is often redundant with the title field of a product).',
      category: 'Configure metafields',
    },
    businessStep: {
      title:
        'Describe your business and how you want your assistant to interact as clearly and concisely as possible',
      description:
        'This text will be used by the assistant to understand your business and how you want it to interact with your customers.',
      category: 'Your business',
      textAreaPlaceholder:
        'You are a sales assistant for Shopx, a retail snowboard brand with an online store. Shopx sells snowboards with and without bindings, boots and snowboard accessories. You engage with customers in a very relaxed way and are always kind and friendly with them',
    },
    businessSkillsStep: {
      title: 'Enter the area of expertise your AI needs to master',
      description:
        'The skills you enter will help our AI to refine these answers for your customers. For instance, for a cosmetic store, it could be Face treatments, Body treatments, Hair treatments and Makeup.',
      category: 'Areas of expertise',
      textAreaPlaceholder: 'Separate keywords pressing enter',
      textAreaDescription: 'Minimum {min} and maximum {max} skills',
    },
    uploadFilesStep: {
      uploadFilesWarning: 'You cannot upload more than 2 files',
      uploadFilesWarningDescription:
        'Upgrade to a paid plan to upload more files',
      title: 'Please, upload additional files that could help us',
      description:
        'Send us any file that could help us understand your business, such as FAQs, Manifesto, Product Guide, etc.',
      category: 'Upload files',
      fileUploadTitle: 'Drop your document here, or click to browse',
      fileUploadDescription: 'TXT, DOCX, PDF supported, up to 50MB.',
      optional: '*Optional',
    },
    configureWorkspaceStep: {
      title: 'Great we can now configure your workspace',
      description: 'We will need your logo and a name for your store.',
      category: 'Set up workspace',
      uploadLogoTitle: 'Upload your logo',
      uploadLogoDescription: 'JPG, PNG, PDF supported, up to 5MB.',
      storeName: 'Store name',
      continue: 'Complete',
    },
    titleForm: 'Create an organization',
    script: 'Script',
    descriptionForm:
      'The information of your organization will be visible to your users through your messages',
    organizationNameLabel: 'Organization name',
    organizationPlaceholder: 'Enter your company name...',
    unavailableOrganizationName: 'This organization name already exists',
    requiredOrganizationName: 'Organization name is required',
    organizationPicture: "Organization's picture",
    gettingStartedTitle: "Paste Dialog's script in your code's site",
    gettingStartedTitleEcommerce: "You're all set!",
    gettingStartedDescription:
      "This code will send your customer's addresses to Dialog once a new user connect his wallet",
    gettingStartedDescriptionEcommerce:
      'Click on continue to start creating your AI assistant',
    continueCta: 'Continue',
    'connect-wallet': 'Connect a company wallet',
    'organization-exists': 'An organization with this name already exists',
    'connect-wallet-description':
      'This will be your identity to chat with your users. We recommend to connect a wallet with an ENS associated so users can verify',
    'create-organization': 'Create an organization',
    code: "This code will send your customer's addresses to Dialog once a new user connect his wallet",
    continue: 'Continue',
    skip: 'Skip this step',
    setup: 'Setup',
    connect: 'Connect Metamask',
    organization: 'Organization',
    done: "It's done!",
    chat: 'Chat',
    'set-up': 'Set up',
    'paste-script': "Paste this script in your code's site",
    'code-explaination':
      "This code will send your customer's addresses to CRM3 once a new user connect his wallet",
  },
  library: {
    title: 'Library',
    subtitle: 'Pick a template to start messaging people',
    allTemplates: 'All templates',
    useTemplate: 'Use this template',
    solutions: {
      solutions: 'Solutions',
      contact: 'Contact',
      feedback: 'Feedback',
      onboarding: 'Onboarding',
      engagement: 'Engagement',
      community: 'Community',
      features: 'Features',
    },
    industries: {
      industries: 'Industries',
      all: 'All industries',
      defi: 'DeFi',
      'nft-marketplaces': 'NFT Marketplaces',
      gaming: 'Gaming',
    },
  },
  inbox: {
    title: 'Inbox',
    opened: 'Opened',
    closed: 'Closed',
    other: 'All',
    closeConversation: 'Close conversation',
    openConversation: 'Open conversation',
    today: 'Today',
    new: '{count} new {count, plural, one {message} other {messages}}',
    placeholder: 'Type in your message...',
    conversation: {
      notFoundConversation: {
        title: 'No new conversation',
        description: 'Every conversation has been treated.',
      },
    },
  },
  smartLabels: {
    select: 'Select smart label...',
    notFound: 'No results found',
    'testnet-user': {
      name: '🧪 Testnet user',
      description: 'Person or smart contract who used a testnet',
    },
    'strong-testnet-user': {
      name: '🧪 Strong testnet user',
      description: 'Person or smart contract who used a testnet ≥100 times',
    },
    'epic-testnet-user': {
      name: '🧪 Epic testnet user',
      description: 'Person or smart contract who used a testnet ≥1000 times',
    },
    'nft-collector': {
      name: '🌠 NFT Collector',
      description: 'Has sent or received ERC721 tokens',
    },
    'diversified-token-holder': {
      name: '🛒 Diversified Token Holder',
      description:
        'An active holder (has transacted with tokens and has a positive token balance) of at least 6 tokens',
    },
    millionaire: {
      name: '💵 Millionaire',
      description: 'Has a (ETH and token) balance of at least $1,000,000',
    },
    billionaire: {
      name: '💰 Billionaire',
      description: 'Has a (ETH and token) balance of at least $1,000,000,000',
    },
    'eth-millionaire': {
      name: '🔗 ETH Millionaire',
      description: 'Has an ETH balance of at least $1,000,000',
    },
    'eth-billionaire': {
      name: '⛓ ETH Billionaire',
      description: 'Has an ETH balance of at least $1,000,000,000',
    },
    'token-millionaire': {
      name: '🤑 Token Millionaire',
      description: 'Has a token balance of at least $1,000,000',
    },
    'token-billionaire': {
      name: '🤑 Token Billionaire',
      description: 'Has a token balance of at least $1,000,000,000',
    },
    'nft-blue-chip-holder': {
      name: '🔵 NFT Blue Chip Holder',
      description:
        'Wallets that are currently holding at least one Blue Chip NFT in their portfolio.',
    },
  },
  messages: {
    header: {
      steps: {
        audience: 'Audience',
        content: 'Content',
      },
    },
    stopSending: 'Stop sending',
    startSending: 'Start sending',
    errorScheduleForm: 'Stop date should be after start date',
    sendingOptions: {
      immediately: 'Immediately',
      customDate: 'Custom Date',
      never: 'Never',
    },
    status: {
      draft: 'Draft',
      live: 'Running',
      paused: 'Paused',
      walletRequired: 'Wallet required',
      sent: 'Sent',
      archived: 'Archived',
      ended: 'Ended',
      scheduled: 'Scheduled',
    },
    title: 'Campaigns',
    library: 'Library',
    campaignsTitle: 'Campaigns',
    campaignTitle: 'Campaign',
    new: 'New message',
    defaultMessageName: 'Untitled',
    defaultMessagePostName: 'New message campaign',
    defaultMessageCarouselName: 'New carousel campaign',
    newCampaign: 'New campaign',
    allMessageTypes: 'All message type',
    postType: 'Posts',
    tutorialType: 'Tutorials',
    allAudienceTypes: 'All audience type',
    fixedAudience: 'Fixed',
    automaticAudience: 'Automatic',
    allStatuses: 'All status',
    liveStatus: 'Live',
    draftStatus: 'Draft',
    pausedStatus: 'Paused',
    sentStatus: 'Sent',
    filters: 'Filters',
    type: 'Type',
    target: 'Target',
    message: 'Message',
    messages: 'messages',
    audience: 'Audience',
    addFilters: 'Add filters',
    editFilters: 'Edit filters',
    where: 'Where',
    audienceType: 'Audience type',
    walletPreviewDescription: 'wallets matching selected filters',
    walletPreviewButton: 'View detailed list',
    messageContentPlaceholder: 'Your message here',
    showAnyPage: 'Show on any page',
    showSpecificPage: 'Show on specific pages',
    showUrlContains: 'Show on URL which contains',
    noMessagesFound: {
      title: 'Create your first message',
      description:
        'Create your first message to interact with your users with personalized experiences based on their wallet',
      buttonLeft: 'Browse library',
      buttonRight: 'New message',
    },
    cancelPopup: {
      title: 'Leave without saving?',
      description: 'When leaving the page you will lose your unsaved changes.',
      cancelButton: 'Stay',
      applyButton: 'Leave anyway',
    },
    duplicatePopup: {
      title: 'Duplicate this message?',
      description: 'The current message modification will be lost if unsaved.',
      cancelButton: 'Cancel',
      applyButton: 'Duplicate',
    },
    endPopup: {
      title: 'End this message?',
      description:
        'By ending this message, it will no longer be able to be turned live and will permanently keep its "ended" status ',
      cancelButton: 'Cancel',
      applyButton: 'End message',
    },
    deletePopup: {
      title: 'Delete this message?',
      description: 'This message will be permanently removed.',
      cancelButton: 'Cancel',
      applyButton: 'Delete',
    },
    publishPopup: {
      title: 'Set this message live?',
      titleScheduled: 'Schedule this message?',
      description:
        'The users belonging to the audience will be subject to see this message once on the platform',
      fromDate: 'from the {from}',
      fromToDate: 'from the {from} to the {to}',
      untilDate: 'until the {until}',
      cancelButton: 'Cancel',
      applyButton: 'Set it live',
      applyButtonSchedule: 'Schedule it',
    },
    resumePopup: {
      title: 'Resume this campaign?',
      description:
        'You will send a message to the new wallets that entered in your segment since you paused it, as well as all the future ones',
      cancelButton: 'Cancel',
      applyButton: 'Resume',
    },
    carousels: {
      header: {
        steps: {
          audience: 'Audience',
          content: 'Content',
        },
      },
      messageSuccessContentPlaceholder: 'Message...',
      successMessageDescription:
        'This message will appear once the user has finished the carousel',
      button: 'Button',
      buttonTextPlaceholder: 'Button...',
      buttonLink: 'Button link',
      buttonLinkPlaceholder: 'https://...',
      titleError: 'A title is required',
      titlePlaceholder: 'Title...',
      contentPlaceholder: 'Content...',
      introduction: 'Introduction',
      carousel: 'Carousel',
      callToAction: 'Call-to-action',
      pages: 'Pages',
      addPage: 'Add page',
      image: 'Image',
      title: 'Title',
      content: 'Content',
      updatePage: 'Update page',
      slides: {
        defaultTitle: 'Sample page',
        defaultContent:
          'This is a placeholder text meant to demonstrate the appearance of written content in a given format. The purpose of this text is to give an idea of how the final page will look, and it is not intended to convey any actual information.',
      },
      carouselDescription:
        'We recommend adding images to your slides to enhance the navigation experience',
    },
    xmtp: {
      organizationWallet: 'Campaign sent by organization wallet',
      walletInfos: {
        title: 'Default wallet',
        description:
          'Your current default wallet to send an XMTP campaign is the organization wallet. If you want to use another wallet, go to XMTP settings page to change it.',
        callToAction: 'Go to XMTP messaging setup page',
      },
      walletMissing: {
        title: 'XMTP message',
        description:
          'Connect a wallet to start sending your users a secure and private direct messaging layer.',
        callToAction: 'Enable XMTP',
      },
    },
    messageDetails: {
      addBanner: 'Add a banner',
      pages: 'Pages',
      userPicturePrompting: {
        titleCurrentUser: 'You do not have a profile picture',
        titleOtherUser: 'This user does not have a profile picture',
        defaultDescription:
          'Using a profile picture can improve your conversion rate.',
        currentUserAdditionalDescription: ' You can add one in your {link}',
        currentUserAdditionalLink: 'profile settings.',
      },
      specificPages: 'Specific pages containing {pages}',
      allPages: 'All pages',
      endCampaign: 'End campaign',
      duplicateMessage: 'Duplicate campaign',
      deleteMessage: 'Delete campaign',
      createTemplate: 'Save as template',
      defaultValues: {
        successMessage: 'Success message...',
        successCtaText: 'Button',
        messageContent: `Hello! I am your dedicated expert for {organizationName}. Investing on DeFi can be overwhelming, would you be interested to have a quick chat? 📞`,
        ctaText: 'Schedule a call',
        carouselCtaText: 'Take the tour',
        carouselContent:
          "Hi there! Welcome aboard, we're so excited to have you join us! We've put together a quick product tour to show you just how amazing our app is.",
      },
      'no-audience': 'No audience',
      'no-filter': 'No filter',
      'no-link': 'No link',
      'no-content': 'No message',
      'no-cta-text': 'No cta message',
      types: {
        post: 'Message',
        tutorial: 'Carousel',
        xmtp: 'XMTP Message',
      },
      audiences: {
        fixed: 'Fixed',
        automatic: 'Dynamic',
        custom: 'Custom filters',
      },
      title: 'Schedule call with whales',
      tabs: {
        tab_1: 'Message',
        tab_2: 'Sent',
        tab_3: 'Viewed',
        tab_4: 'Clicked',
      },
      states: {
        live: 'Running',
        paused: 'Paused',
        liveDescription: 'Activate your message and display it to your users',
        pausedDescription: "Pause your message, users won't see it",
        draft: 'Draft',
        sent: 'Sent',
        ended: 'Ended',
        walletRequired: 'Wallet required',
        walletRequiredDescription: 'Wallet required',
        scheduled: 'Scheduled',
      },
      sentTable: {
        sent: 'Sent',
        sentDescription:
          'Users will receive the in-app campaign immediately upon connecting.',
        clickedDescription: 'Users who clicked on the CTA button.',
        viewedDescription: 'Users who saw the in-app campaign upon connecting.',
        clicked: 'Clicked',
        viewed: 'Viewed',
        notViewed: 'Not yet',
        notClicked: 'No click',
        searchbarPlaceholder: 'Search for users...',
      },
      buttonMessageTitle: 'Button message',
      buttonLink: 'Button link',
      messageType: 'Campaign type',
    },
    newMessage: {
      ctaGroupTitle: 'Call-to-action',
      campaignSentBy: 'Campaign sent by',
      defaultMessageTitle: 'New in-app message',
      title: 'Schedule call with whales',
      description: 'Post',
      ctaColor: 'Button color',
      ctaText: 'Button',
      ctaTextPlaceholder: 'Button',
      ctaLink: 'Button link',
      ctaLinkPlaceholder: 'https://askdialog.com',
      ctaTextColor: 'Button text color',
      from: 'In-app message sent by',
      automatic: 'Dynamic',
      automaticDescription:
        'Send the message to people who match the filters now and in the future',
      fixed: 'Fixed',
      fixedDescription:
        'Send the message only to people who match the filters now',
      createAudience: 'Create a new audience',
      userSegment: 'User in a segment',
      customFilters: 'Custom filters',
      allUser: 'All users',
    },
    campaign: {
      titleSg: 'Campaign',
      titlePl: 'Campaigns',
      new: 'New campaign',
      newDescription: 'Choose the type of campaign you want to create.',
      items: {
        messageTitle: 'Message',
        messageDescription:
          'Create a simple and custom message, perfect for a direct approach.',
        carouselTitle: 'Carrousel',
        carouselDescription:
          'Create a carousel of content, ideal for a more detailed approach.',
        xmtpTitle: 'XMTP Message',
        xmtpDescription:
          'Provide your users a secure and private direct messaging layer.',
      },
    },
  },
  smartContracts: {
    title: 'Smart contracts',
    subscriptions: 'Subscriptions',
    noSubscription:
      'Subscribe to smart contract to record activity on your wallets',
    newSubscription: 'New subscription',
    newSubscriptionForm: {
      title: 'Track smart contracts events',
      selectChainLabel: 'Chain',
      contractAddressLabel: 'Contract address',
      contractAddressError:
        'Contract address must start with "0x..." and must have exactly 40 chars',
      submitButton: 'Subscribe',
      eventList: '{tracked}/{total} events tracked',
      loadingPreviewError: 'Smart contract not found',
      errorAlreadyExists:
        'It seems you already subscribded to this smart contract',
    },
    editSubscriptionForm: {
      title: 'Manage tracked events',
      submitButton: 'Save changes',
      eventList: '{tracked}/{total} events tracked',
      loadingPreviewError: 'Smart contract not found',
    },
    table: {
      headers: {
        contractAddress: 'Contract address',
        chainId: 'Chain',
        eventTypes: 'Event types',
        subscribedEvents: 'Subscribed events',
      },
    },
    unsubscribe: {
      title: 'Cancel subscription',
      description: 'You will not receive any event from the smart contract:',
      areYouSure: 'Do you really want to unsubscribe ?',
      cancel: 'Stay subscribed',
      unsubscribe: 'Unsubscibe',
    },
    edit: {
      title: 'Edit subscription',
    },
  },
  buttons: {
    translate: 'Translate',
    generate: 'Generate',
    cancel: 'Cancel',
    saveLater: 'Save for later',
    saveAsDraft: 'Save as draft',
    live: 'Set it live',
    scheduleMessage: 'Schedule message',
    continue: 'Continue',
    edit: 'Edit',
    save: 'Save',
    next: 'Next',
    back: 'Back',
    delete: 'Delete',
    update: 'Update',
    import: 'Import',
    add: 'Add',
    disable: 'Disable',
  },
  placeholders: {
    enterTitle: 'Enter a title',
    deleteCaps: 'DELETE',
  },
  toasts: {
    success: 'Success',
    error: 'Error',
    info: 'Information',
    alert: 'Alert',
  },
  hooks: {
    aggregation: {
      deleteSelectedWallets: {
        titleSuccess: 'Current wallets deleted',
        descriptionSuccess:
          'All current wallets from this segment has been deleted successfully',
        descriptionError: 'Oups! An error occured!',
      },
    },
    shared: {
      fileUploadDescriptionSizeError: 'Picture should be under 3MB!',
      documentUploadDescriptionSizeError: 'Document should be under 50MB!',
    },
    organizationIntegration: {
      enableSuccessDescription: 'Segment has been enabled!',
      disabledSuccessDescription: 'Segment has been disabled!',
      errorDescription: 'Oups! An error occured!',
    },
    wallets: {
      importValidateCsvDefaultErrorDescription:
        'Something wrong happened during validation',
      fileSizeLimitTitle: 'Row limit exceeded !',
      fileSizeLimitDescription:
        'You have exceeded the row limit authorized < {limit}',
      importValidatedCsvTitleSuccess: 'Importation is in progress...',
      importValidatedCsvDescriptionSuccess:
        'You will receive a confirmation email upon completion of the import',
      exportWalletsTitleCsv: 'Export is in progress...',
      exportWalletsDescriptionCsv:
        'CSV will be available in your download folder in a few seconds.',
      updateOrganizationWalletData: {
        descriptionError: 'Oups! An error occured!',
        wrongEmailError: 'Wrong email format',
      },
    },
    organization: {
      updateSuccess: 'Organization has been successfully updated',
      updateError: 'Oups ! An error occured during organization update',
      updateOrganizationPictureDescriptionError:
        'Oups! An error occured during picture upload 🙁',
      updateOrganizationPictureDescriptionFormatError:
        'Picture should be in a .png, .jpg or .jpeg format!',
      updateOrganizationPictureDescriptionSizeError:
        'Picture should be under 3MB!',
      requestPlanUpgradeSuccessTitle: 'Thank you !',
      requestPlanUpgradeSuccessDescription:
        'Our team will contact you to discuss the best plan for you.',
      requestPlanUpgradeError: 'Oups! An error occurred 🙁.',
    },
    auth: {
      updateUserPictureDescriptionError:
        'Oups! An error occured during picture upload 🙁',
      updateUserPictureDescriptionFormatError:
        'Picture should be in a .png, .jpg or .jpeg format!',
      updateUserPictureDescriptionSizeError: 'Picture should be under 3MB!',
      removeUserFromOrgTitleSuccess:
        '{name} has been removed from your organization',
      removeUserFromOrgDescriptionSuccess:
        'This user has no longer access to your organization',
      removeUserFromOrgFailed: 'Oups! An error occured 🙁',
      loginFailed: 'Oups! An error occured during connection, wrong email 🙁?',
      registerFailed: 'Oups! An error occured during sign up 🙁!',
      unknownUser: `User not found 🙁, you may verify your email!{lineBreak}If you created your account via Shopify, use the email address linked to your Shopify account.`,
      UsernameExistsException: 'Email already used 🙁!',
      inviteUserTitle: 'Invitation has been sent',
      inviteUserDescription: 'An invitation link has been sent to {email}',
      inviteUserDescriptionFailed:
        'Oups! An error occured during invitation 🙁',
    },
    messages: {
      createSuccess: 'Message has been created 🎉!',
      createFailed: 'Oups! An error occurred during message creation 🙁.',
      updateSuccess: 'Message has been updated 🎉!',
      updateFailed: 'Oups! An error occurred during message update 🙁. ',
      updateStatusSuccess: 'Message status has been changed 🎉!',
      updateStatusFailed: 'Oups! An error occurred 🙁.',
      archiveTitle: 'Message deleted',
      archiveSuccess: 'The message has been deleted successfully 🎉!',
      archiveFailed: 'Oups! An error occurred during message deletion 🙁.',
      templateCreateSuccess: 'Template has been created 🎉!',
      templateCreateFailed:
        'Oups! An error occurred during template creation 🙁.',
      invalidScheduling: 'Scheduled message has invalid start or/and end date!',
    },
    segments: {
      uniqueErrorDescription:
        'Segment name should be unique, {name} is already used.',
      defaultErrorDescription:
        'Oups! An error occured during segment creation 🙁',
      deleteTitleSuccess: 'Segment deleted',
      deleteSuccess: 'The segment has been deleted successfully 🎉!',
      deleteFailed: 'Oups! An error occured during message deletion 🙁.',
    },
  },
  shared: {
    search: 'Search...',
    emptyListItem: {
      title: 'No results found',
      description: 'We have not found any results matching your search',
      cta: 'Clear search',
    },
  },
  errors: {
    invalidEmail: 'Wrong email format (name@provider.com)',
    userIsNotAuthorizedToPerformAction:
      'You do not have the right access to perform this action',
    userHasAlreadyOrganization: 'User has already an organization',
    userIsAlreadyInOrganization: 'User is already in organization',
    userAccountAlreadyExist: 'User already exist',
    internalError: 'Something unexpected happened',
    userNotExist: "User doesn't exist",
    notAllowToJoin: 'User is not allowed to join this organization',
    splashScreen: {
      title: 'Oops! Something went wrong',
      description:
        'Sorry for the inconvenience, you may reload the page. If the error persists please',
      cta: 'Reload',
    },
    splashScreenWithCustomError: {
      title: 'Oops! Something went wrong',
      description: 'Sorry for the inconvenience, If the error persists please',
      cta: 'Reload',
      cause: 'Cause: {cause}',
    },
  },
};
export default enUSMessages;
