import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { memo, Suspense, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  PlusIcon,
  SidebarMenu,
  spacings,
  theme,
} from '@dialog/design-system';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { RoutePaths } from 'config';
import {
  getAllWalletsSegment,
  Segment,
  useSegment,
} from 'context/SegmentProvider';
import { useUpdateSegment } from 'hooks/segments';
import { lazyWithRetry } from 'utils';

import { DraggableList } from '../DraggableList/DraggableList';
import PlanLimit from './PlanLimit';
import { SegmentItemWrapper } from './SegmentItemWrapper';

const SaveSegmentModal = lazyWithRetry(() => import('./SaveSegmentModal'));

export const SegmentSidebar = ({
  onSegmentCreated,
}: {
  onSegmentCreated?: () => void;
}): JSX.Element => {
  const intl = useIntl();
  const { allSegments, segmentData, setSegmentsOrdered } = useSegment();
  const updateSegment = useUpdateSegment();
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const allWalletsSegment = getAllWalletsSegment();

  const onSegmentClick = (newSegment: Segment) => {
    navigate({
      pathname: RoutePaths.HOME_PAGE,
      search: `?segmentId=${newSegment.id}`,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const [openCreateSegmentModal, setOpenCreateSegmentModal] = useState(false);
  const handleClose = () => setOpenCreateSegmentModal(false);
  const onNewSegmentClick = () => setOpenCreateSegmentModal(true);

  const getSegmentName = (segment: Segment) =>
    (segment.translationKey !== undefined
      ? intl.formatMessage({ id: segment.translationKey })
      : segment.name) ?? '';

  const onUpdateOrder = async (segments: Segment[]) => {
    setSegmentsOrdered(segments);
    await Promise.all(
      segments.map((segment, index) =>
        updateSegment.mutateAsync({
          organizationSlug: organization.slug,
          segmentId: segment.id,
          order: index,
        }),
      ),
    );
  };

  const othersSegments = allSegments.filter(
    segment => segment.id !== allWalletsSegment.id,
  );

  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      })}
    >
      <SidebarMenu
        title="Segments"
        customCss={css({
          display: 'block',
          height: 'auto',
          flex: 1,
        })}
      >
        <>
          <SegmentItemWrapper
            segment={allWalletsSegment}
            currentSegment={segmentData.selectedSegment}
            getSegmentName={getSegmentName}
            onSelectSegment={onSegmentClick}
          />

          <DraggableList<Segment>
            customSensors={sensors}
            items={othersSegments}
            onUpdate={onUpdateOrder}
          >
            {othersSegments.map(segment => (
              <SegmentItemWrapper
                key={segment.id}
                segment={segment}
                currentSegment={segmentData.selectedSegment}
                getSegmentName={getSegmentName}
                onSelectSegment={onSegmentClick}
              />
            ))}
          </DraggableList>
          <Button
            variant="blank"
            iconLeft={<PlusIcon color={theme.palette.grey600} />}
            customCss={css({
              padding: `${spacings.xs} ${spacings.md}`,
              width: '100%',

              span: {
                fontWeight: theme.typography.body2.fontWeight,
              },
            })}
            onClick={onNewSegmentClick}
            onMouseOver={() => import('./SaveSegmentModal')}
          >
            <FormattedMessage id="segments.newSegment" />
          </Button>

          {openCreateSegmentModal && (
            <Suspense fallback={<LoadingButton />}>
              <SaveSegmentModal
                open={openCreateSegmentModal}
                onClose={handleClose}
                onSegmentCreated={onSegmentCreated}
              />
            </Suspense>
          )}
        </>
      </SidebarMenu>
      <PlanLimit />
    </div>
  );
};

export const MemoSegmentSidebar = memo(SegmentSidebar);
