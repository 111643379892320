import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { RoutePaths } from 'config';

const baseApiUrl = process.env.VITE_API_URL;

const client = axios.create({
  baseURL: baseApiUrl,
});

const handleError = (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = RoutePaths.LOGIN;
  }

  throw error;
};

const handleResponse = (response: AxiosResponse) => response;

export const createAuthClient = async (): Promise<AxiosInstance> => {
  let token = '';
  try {
    token = (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (error) {
    console.error(error);
  }

  const clientAuthentified = axios.create({
    baseURL: baseApiUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  clientAuthentified.interceptors.response.use(handleResponse, handleError);

  return clientAuthentified;
};

export default client;
