import { css, SerializedStyles } from '@emotion/react';

import { getSpacing, theme } from '@dialog/design-system';

export interface ILabel {
  children?: JSX.Element | string;
  marginRight?: number;
  marginLeft?: number;
  customCss?: SerializedStyles | SerializedStyles[];
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  onClick?: React.MouseEventHandler;
}

const buttonStyle = ({ marginRight, marginLeft, onClick }: Partial<ILabel>) =>
  css({
    fontSize: `${theme.typography.body2.fontSize}px`,
    marginLeft,
    marginRight,
    display: 'flex',
    alignItems: 'center',
    outline: 'unset',
    border: 'unset',
    background: 'unset',
    width: '100%',
    padding: '0',
    cursor: onClick !== undefined ? 'pointer' : 'unset',

    '&:hover': {
      opacity: onClick !== undefined ? 0.8 : 1,
    },
  });

const spanStyle = ({ icon, iconPosition }: Partial<ILabel>) =>
  css({
    marginLeft: icon && iconPosition === 'left' ? getSpacing(1) : 0,
    marginRight: icon && iconPosition === 'right' ? getSpacing(1) : 0,
    width: '100%',
    textAlign: 'left',
  });

export const Label = ({
  children,
  marginRight,
  marginLeft,
  customCss,
  icon,
  iconPosition = 'left',
  onClick,
}: ILabel): JSX.Element => {
  return (
    <button
      type="button"
      onClick={onClick}
      css={[
        buttonStyle({
          marginRight,
          marginLeft,
          onClick,
        }),
        customCss,
      ]}
    >
      {icon && iconPosition === 'left' ? icon : null}
      <span css={spanStyle({ icon, iconPosition })}>{children}</span>
      {icon && iconPosition === 'right' ? icon : null}
    </button>
  );
};

export default Label;
