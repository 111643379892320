import { WalkthroughModalProvider } from 'components/WalkthroughModalProvider/WalkthroughModalProvider';
import { WalkthroughModal } from 'pages/WalkthroughModal';

export const WithWalkthroughModal = ({
  children,
  walkthroughModalActivated,
}: {
  children: JSX.Element | JSX.Element[];
  walkthroughModalActivated: boolean;
}): JSX.Element => {
  if (walkthroughModalActivated) {
    return (
      <WalkthroughModalProvider>
        <>
          {children}
          <WalkthroughModal />
        </>
      </WalkthroughModalProvider>
    );
  }

  return <>{children}</>;
};
