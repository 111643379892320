import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/react';
import { Key } from 'react';

export const DraggableItem = ({
  id,
  children,
  isDragged = false,
}: {
  id: Key;
  children: JSX.Element;
  isDragged?: boolean;
}): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      key={`draggable-item-${id}`}
      css={css({
        ...style,
        cursor: isDragged ? 'grab' : 'pointer',
      })}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
};
