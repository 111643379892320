import { ErrorObject } from 'ajv';
import { AxiosError } from 'axios';
export enum LoginErrorType {
  NOT_FOUND = 'user-not-found',
}

export enum SignInErrorType {
  USER_ALREADY_EXISTS = 'UsernameExistsException',
}

export enum GenericErrorType {
  UNKNOWN_ERROR = 'unknown-error',
}

interface BackendError
  extends AxiosError<{ message: string; errorType: string }> {
  response: AxiosError['response'] & {
    data: { message: string; errorType: string };
  };
}

interface BackendAjvError extends AxiosError<ErrorObject[]> {
  response: AxiosError['response'] & {
    data: ErrorObject[];
  };
}

export const isBackendError = (error: AxiosError): error is BackendError => {
  return (
    typeof error.response === 'object' &&
    'data' in error.response &&
    error.response.data !== null &&
    typeof error.response.data === 'object' &&
    'message' in error.response.data &&
    'errorType' in error.response.data &&
    typeof (error as BackendError).response.data.message === 'string' &&
    typeof (error as BackendError).response.data.errorType === 'string'
  );
};

export const isBackendAjvError = (
  error: AxiosError,
): error is BackendAjvError => {
  return (
    typeof error.response === 'object' &&
    'data' in error.response &&
    error.response.data !== null &&
    Array.isArray(error.response.data)
  );
};
