import { getAxiosRequest } from '@swarmion/serverless-contracts';
import axios, { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import {
  SegmentErrorType,
  UpdateSegment,
  updateSegmentContract,
} from '@dialog/organization-contracts';

import { useToasts } from 'context/ToastProvider';
import { isBackendError } from 'errors';
import { createAuthClient } from 'services/networking/client';

import { useGenerateSegmentsKey } from './useGetSegments';

interface IUseUpdateSegmentParams {
  onSuccess?: () => void;
  onError?: () => void;
}
export type IUseUpdateSegmentResponse = UseMutationResult<
  void,
  unknown,
  UpdateSegment & { segmentId: string; organizationSlug: string }
>;
export const useUpdateSegment = ({
  onSuccess,
  onError,
}: IUseUpdateSegmentParams = {}): IUseUpdateSegmentResponse => {
  const { showToast } = useToasts();
  const intl = useIntl();
  const segmentsKey = useGenerateSegmentsKey();
  const queryClient = useQueryClient();

  const onMutationSuccess = async () => {
    await queryClient.invalidateQueries(segmentsKey);

    if (onSuccess) {
      onSuccess();
    }
  };

  const onMutationError = (error: AxiosError, { name }: { name?: string }) => {
    let description = intl.formatMessage({
      id: 'hooks.segments.defaultErrorDescription',
    });

    if (
      axios.isAxiosError(error) &&
      isBackendError(error) &&
      error.response.data.errorType === SegmentErrorType.UNIQUE
    ) {
      description = intl.formatMessage(
        {
          id: `hooks.segments.uniqueErrorDescription`,
        },
        {
          name,
        },
      );
    }
    showToast({
      type: 'error',
      description,
    });

    if (onError) {
      onError();
    }
  };

  return useMutation(
    async ({
      segmentId,
      name,
      filter,
      columns,
      organizationSlug,
      order,
    }: UpdateSegment & { segmentId: string; organizationSlug: string }) => {
      await getAxiosRequest(updateSegmentContract, await createAuthClient(), {
        pathParameters: {
          organizationSlug,
          segmentId,
        },
        body: {
          name,
          filter,
          columns,
          order,
        },
      });
    },
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  );
};
