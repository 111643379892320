import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';

import { Button, spacings, theme, Typography } from '@dialog/design-system';

import { ErrorImage } from './ErrorImage';

export const SplashScreen = (): JSX.Element => {
  const reloadPage = () => {
    location.reload();
  };

  return (
    <div
      css={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '400px',
        margin: '0 auto',
        textAlign: 'center',
      })}
    >
      <ErrorImage />
      <p css={css({ marginTop: spacings.xxl })}>
        <Typography variant="base-md">
          <FormattedMessage id="errors.splashScreen.title" />
        </Typography>
      </p>
      <p
        css={css({
          marginTop: spacings.sm,
          marginBottom: spacings.lg,
        })}
      >
        <Typography
          variant="sm-reg"
          customCss={css({ color: theme.palette.grey600 })}
        >
          <FormattedMessage id="errors.splashScreen.description" />
        </Typography>
        <a
          target="_blank"
          href="mailto:contact@askdialog.com"
          css={css({
            textDecoration: 'none',
            marginLeft: spacings.xs,
            display: 'inline-flex',
            alignItems: 'center',
            width: 'fit-content',
          })}
          rel="noreferrer"
        >
          <Typography
            variant="sm-md"
            customCss={css({
              textTransform: 'lowercase',
              color: theme.palette.purple600,
              marginRight: spacings.sm,
            })}
          >
            <FormattedMessage id="contactSupport" />
          </Typography>
        </a>
      </p>

      <Button variant="secondary" onClick={reloadPage}>
        <FormattedMessage id="errors.splashScreen.cta" />
      </Button>
    </div>
  );
};
