export const WalletIcon = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8333 17.5C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2334 17.5 15.7667 17.5 14.8333V9.33331C17.5 8.39989 17.5 7.93318 17.3183 7.57666C17.1586 7.26306 16.9036 7.00809 16.59 6.8483C16.2335 6.66665 15.7668 6.66665 14.8333 6.66665L5.16667 6.66665C4.23325 6.66665 3.76654 6.66665 3.41002 6.8483C3.09641 7.00809 2.84144 7.26306 2.68166 7.57666C2.5 7.93318 2.5 8.39989 2.5 9.33331L2.5 14.8333C2.5 15.7667 2.5 16.2334 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333Z"
      fill="#C5C4F0"
    />
    <path
      d="M14.1667 12.0833C14.1667 12.3134 13.9801 12.5 13.75 12.5C13.5199 12.5 13.3333 12.3134 13.3333 12.0833C13.3333 11.8532 13.5199 11.6666 13.75 11.6666C13.9801 11.6666 14.1667 11.8532 14.1667 12.0833Z"
      fill="#C5C4F0"
    />
    <path
      d="M13.3333 6.66665V3.75052C13.3333 3.05741 13.3333 2.71085 13.1873 2.49787C13.0598 2.3118 12.8622 2.18539 12.6398 2.14755C12.3852 2.10423 12.0706 2.24946 11.4413 2.53991L4.04918 5.95164C3.48792 6.21068 3.20729 6.3402 3.00175 6.54108C2.82005 6.71866 2.68135 6.93544 2.59625 7.17484C2.5 7.44564 2.5 7.75471 2.5 8.37286V12.5M13.75 12.0833H13.7583M2.5 9.33331L2.5 14.8333C2.5 15.7667 2.5 16.2334 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2334 17.5 15.7667 17.5 14.8333V9.33331C17.5 8.39989 17.5 7.93318 17.3183 7.57666C17.1586 7.26306 16.9036 7.00809 16.59 6.8483C16.2335 6.66665 15.7668 6.66665 14.8333 6.66665L5.16667 6.66665C4.23325 6.66665 3.76654 6.66665 3.41002 6.8483C3.09641 7.00809 2.84144 7.26306 2.68166 7.57666C2.5 7.93318 2.5 8.39989 2.5 9.33331ZM14.1667 12.0833C14.1667 12.3134 13.9801 12.5 13.75 12.5C13.5199 12.5 13.3333 12.3134 13.3333 12.0833C13.3333 11.8532 13.5199 11.6666 13.75 11.6666C13.9801 11.6666 14.1667 11.8532 14.1667 12.0833Z"
      stroke="#181825"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
