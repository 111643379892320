import { useIntl } from 'react-intl';
import { useMutation, UseMutationResult } from 'react-query';

import { useToasts } from 'context/ToastProvider';
import { requestPlanUpgrade } from 'services/fetch';

interface IUseRequestPlanUpgradeParams {
  onSuccess?: () => void;
  onError?: () => void;
}
export type IUseRequestPlanUpgradeResponse = UseMutationResult<
  void,
  unknown,
  { organizationSlug: string }
>;
export const useRequestPlanUpgrade = ({
  onSuccess,
  onError,
}: IUseRequestPlanUpgradeParams = {}): IUseRequestPlanUpgradeResponse => {
  const { showToast } = useToasts();
  const intl = useIntl();

  const onMutationSuccess = () => {
    showToast({
      type: 'success',
      title: intl.formatMessage({
        id: 'hooks.organization.requestPlanUpgradeSuccessTitle',
      }),
      description: intl.formatMessage({
        id: 'hooks.organization.requestPlanUpgradeSuccessDescription',
      }),
    });

    if (onSuccess) {
      onSuccess();
    }
  };

  const onMutationError = () => {
    showToast({
      type: 'error',
      description: intl.formatMessage({
        id: 'hooks.organization.requestPlanUpgradeError',
      }),
    });

    if (onError) {
      onError();
    }
  };

  return useMutation(
    async ({ organizationSlug }: { organizationSlug: string }) =>
      requestPlanUpgrade(organizationSlug),
    {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
    },
  );
};
