import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import 'reset.css';

import App from './App';

const container = document.getElementById('root');

if (container === null) {
  throw new Error('Could not find root element');
}

const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
