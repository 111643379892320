import { useMutation, UseMutationResult } from 'react-query';

import { WidgetSettings } from '@dialog/search-contracts';

import { updateWidgetSettings } from 'services/fetch';

export interface IUseUpdateWidgetSettings {
  onSuccess?: () => void;
  onError?: () => void;
}
export type IUseUpdateWidgetSettingsResponse = UseMutationResult<
  void,
  unknown,
  WidgetSettings
>;
export const useUpdateWidgetSettings = ({
  onSuccess,
  onError,
}: IUseUpdateWidgetSettings = {}): IUseUpdateWidgetSettingsResponse => {
  return useMutation(
    async (data: WidgetSettings) => updateWidgetSettings(data),
    {
      onSuccess,
      onError,
    },
  );
};
