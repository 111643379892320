import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { WalkthroughOrganization } from '@dialog/organization-contracts';

import { getWalkthroughOrganization } from 'services/fetch';

import { getGenerateWalkthroughsKey } from './keys';

export const useGetWalkthroughOrganization = ({
  organizationSlug,
  onSuccess,
  onError,
}: {
  organizationSlug: string;
  onSuccess?: (walkthrough: WalkthroughOrganization) => void;
  onError?: (err: AxiosError) => void;
}): UseQueryResult<WalkthroughOrganization> => {
  const key = getGenerateWalkthroughsKey({ organizationSlug });

  return useQuery(
    key,
    async () => getWalkthroughOrganization({ organizationSlug }),
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
    },
  );
};
