import { css } from '@emotion/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, CheckIcon, spacings, theme } from '@dialog/design-system';
import { OrganizationPlans } from '@dialog/organization-contracts';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { useRequestPlanUpgrade } from 'hooks/organization';

export const PlanLimit = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  const { organization } = useOrganization();

  const requestPlanUpgrade = useRequestPlanUpgrade({
    onSuccess: () => setHasRequested(true),
  });

  const handleContact = async () => {
    setLoading(true);
    await requestPlanUpgrade.mutateAsync({
      organizationSlug: organization.slug,
    });
    setLoading(false);
  };

  if (organization.plan !== OrganizationPlans.FREE) {
    return <></>;
  }

  return (
    <div
      css={css({
        width: '260px',
        backgroundColor: theme.palette.grey50,
        borderRight: `1px solid ${theme.palette.grey100}`,
        padding: spacings.lg,
      })}
    >
      <div
        css={css({
          display: 'flex',
          flexDirection: 'column',
          width: '228px',
          backgroundColor: theme.palette.classic.white,
          borderRadius: theme.borderRadius.md,
          padding: spacings.lg,
          gap: spacings.sm,
        })}
      >
        <div>
          {hasRequested ? (
            <Button
              variant="blank"
              size="md"
              customCss={css({
                marginTop: spacings.sm,
                cursor: 'unset',
                background: theme.palette.classic.white,

                '&:hover': {
                  background: theme.palette.classic.white,
                },

                '>span': { color: theme.palette.green500 },
              })}
              iconLeft={<CheckIcon color={theme.palette.green500} />}
            >
              <FormattedMessage
                id="segments.planLimit.contacted"
                defaultMessage="Contacted"
              />
            </Button>
          ) : (
            <Button
              variant="primary"
              size="md"
              onClick={handleContact}
              customCss={css({ marginTop: spacings.sm })}
              disabled={loading}
            >
              <FormattedMessage
                id="segments.planLimit.cta"
                defaultMessage="Contact us"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanLimit;
