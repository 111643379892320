import { ComponentType, lazy, LazyExoticComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetry = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> => {
  const retryImport = async () => {
    try {
      return await factory();
    } catch (error) {
      for (let i = 0; i < 5; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        try {
          return await factory();
        } catch (e) {
          console.log('retrying import');
        }
      }
      throw error;
    }
  };

  return lazy(retryImport);
};
