import { css, keyframes } from '@emotion/react';

import { SpinnerIcon, theme } from '@dialog/design-system';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const animatedLoaderStyle = css({
  animation: `${rotate} 0.66s linear infinite`,
});

export const PageLoader = (): JSX.Element => {
  return (
    <div
      css={css({
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      })}
    >
      <div>
        <SpinnerIcon
          color={theme.palette.purple600}
          customCss={animatedLoaderStyle}
        />
      </div>
    </div>
  );
};
