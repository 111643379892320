import { css } from '@emotion/react';

import { spacings, theme } from '@dialog/design-system';

import { Segment } from 'context/SegmentProvider';
import { getEmojiFromString } from 'utils/emoji';

import SegmentItem from './SegmentItem';

export const SegmentItemWrapper = ({
  segment,
  currentSegment,
  onSelectSegment,
  getSegmentName,
}: {
  segment: Segment;
  currentSegment: Segment;
  onSelectSegment: (segment: Segment) => void;
  getSegmentName: (segment: Segment) => string;
}): JSX.Element => {
  const segmentName = getSegmentName(segment);
  const [emoji, name] = getEmojiFromString(segmentName);

  return (
    <SegmentItem
      key={segment.id}
      segment={segment}
      currentSegment={currentSegment}
      onSelectSegment={onSelectSegment}
      segmentName={
        <>
          {emoji.length > 0 ? (
            <span
              css={css({
                paddingRight: spacings.md,
                fontSize: theme.typography.h3.fontSize,
                verticalAlign: 'bottom',
              })}
            >
              {emoji}
            </span>
          ) : (
            <></>
          )}

          <span>{name}</span>
        </>
      }
    />
  );
};
