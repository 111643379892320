export const getEmojiFromString = (
  stringWithEmoji: string,
): [string, string] => {
  const emojiRE =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/;
  const match = stringWithEmoji.match(emojiRE);
  if (match === null) {
    return ['', stringWithEmoji];
  }
  const emojisConcated = match.reduce((acc, item) => acc.concat(item), '');
  const emoji = stringWithEmoji
    .slice(0, emojisConcated.length)
    .replace(' ', '');
  const text = stringWithEmoji
    .slice(emojisConcated.length, stringWithEmoji.length)
    .replace(' ', '');

  return [emoji, text];
};
