import { useMutation, UseMutationResult } from 'react-query';

import { WalkthroughOrganization } from '@dialog/organization-contracts';

import { updateWalkthroughOrganization } from 'services/fetch';

export const useUpdateWalkthroughOrganization = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}): UseMutationResult<
  WalkthroughOrganization,
  unknown,
  WalkthroughOrganization
> => {
  return useMutation(
    async (params: WalkthroughOrganization) =>
      updateWalkthroughOrganization(params),
    {
      onSuccess,
      onError,
    },
  );
};
