import { css, SerializedStyles } from '@emotion/react';

import { spacings, theme } from '@dialog/design-system';

import { Segment } from 'context/SegmentProvider';

import { Label } from '../Label/Label';

export const itemHoverActiveStyle = (
  currentSegment: Segment | undefined,
  segment: Segment | undefined,
): SerializedStyles =>
  css({
    backgroundColor:
      currentSegment?.id === segment?.id ? theme.palette.grey100 : 'unset',

    color:
      currentSegment?.id === segment?.id
        ? theme.palette.classic.black
        : theme.palette.grey600,

    '&:hover': {
      opacity: currentSegment?.id === segment?.id ? '1' : 'inherit',
      backgroundColor: theme.palette.grey100,
    },
  });

export const defaultItemStyle = css({
  fontWeight: theme.typography.body2.fontWeight,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: `${spacings.xl}`,
});

interface ISegmentItem {
  segment: Segment;
  currentSegment: Segment | undefined;
  onSelectSegment: (segment: Segment) => void;
  segmentName: string | JSX.Element;
}
export const SegmentItem = ({
  segment,
  currentSegment,
  onSelectSegment,
  segmentName,
}: ISegmentItem): JSX.Element => (
  <Label
    customCss={[
      css({
        padding: `${spacings.sm} ${spacings.md}`,
        borderRadius: theme.borderRadius.md,
        marginBottom: spacings.xs,

        svg: {
          marginRight: spacings.xs,
          overflow: 'initial',
        },
      }),
      itemHoverActiveStyle(currentSegment, segment as unknown as Segment),
      defaultItemStyle,
    ]}
    key={segment.id}
    icon={segment.icon}
    onClick={() => onSelectSegment(segment)}
  >
    {segmentName}
  </Label>
);

export default SegmentItem;
