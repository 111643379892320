import { OrganizationProvider } from 'components/OrganizationProvider/OrganizationProvider';

export const WithOrganization = ({
  children,
  requireOrganization,
}: {
  children: JSX.Element | JSX.Element[];
  requireOrganization: boolean;
}): JSX.Element => {
  if (requireOrganization) {
    return <OrganizationProvider>{children}</OrganizationProvider>;
  }

  return <>{children}</>;
};
