import * as Sentry from '@sentry/react';

import { SplashScreen } from './SplashScreen';

export const SentryErrorBoundary = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <Sentry.ErrorBoundary fallback={<SplashScreen />}>
    {children}
  </Sentry.ErrorBoundary>
);
