/* eslint max-lines: ["error", 300] */
import { css } from '@emotion/react';
import { animated, useSpring } from '@react-spring/web';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Accordion,
  Button,
  Checkbox,
  CrossIcon,
  getSmallSpacing,
  spacings,
  theme,
  Typography,
} from '@dialog/design-system';
import { WalkthroughOrganization } from '@dialog/organization-contracts';

import { useOrganization } from 'components/OrganizationProvider/OrganizationProvider';
import { useWalkthroughModal } from 'components/WalkthroughModalProvider/WalkthroughModalProvider';
import { useUpdateWalkthroughOrganization } from 'hooks/organization';
interface IStep {
  title: string;
  description: string;
  idCheckbox: keyof WalkthroughOrganization;
  videoUrl?: string;
  checked: boolean;
  values?: Record<string, string | ReactNode>;
}
[];

const formatGroups = (
  steps: IStep[],
  handleCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: keyof WalkthroughOrganization,
  ) => void,
) => {
  return steps.map(step => ({
    id: step.idCheckbox,
    label: 'Step 1',
    customHeader: (
      <Checkbox
        key={`step-${step.idCheckbox}-${step.checked.toString()}`}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          handleCheck(evt, step.idCheckbox)
        }
        defaultChecked={step.checked}
        id={step.idCheckbox}
        customCss={css({
          '&:hover': {
            'input::before': {
              border: 'unset',
            },
          },
        })}
      >
        <Typography variant="sm-md">
          <FormattedMessage id={step.title} />
        </Typography>
      </Checkbox>
    ),
    customContentCss: css({
      paddingLeft: 0,

      '&:last-of-type': {
        padding: 0,
      },
    }),
    customWrapperCss: css({
      '&:not(&:last-of-type)': {
        marginBottom: spacings.sm,
      },
    }),
    customHeaderCss: css({
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    }),
    toggleOnlyOnArrow: true,
    customContent: (
      <div
        css={css({
          background: theme.palette.grey50,
          paddingLeft: '40px',
          paddingBottom: spacings.md,
          display: 'flex',
          flexDirection: 'column',
          gap: spacings.sm,
        })}
      >
        <Typography variant="sm-reg" color={theme.palette.grey600}>
          <FormattedMessage id={step.description} values={step.values} />
        </Typography>

        {step.videoUrl !== undefined && (
          <div
            className="lo-emb-vid"
            css={css({
              position: 'relative',
              paddingBottom: '75%',
              height: 0,
            })}
          >
            <iframe
              title="loom"
              src={step.videoUrl}
              css={css({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              })}
              frameBorder="0"
              // @ts-expect-error code from loom is valid
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen={true}
            />
          </div>
        )}
      </div>
    ),
    items: [
      {
        value: 'step1',
        render: <></>,
      },
    ],
  }));
};

const opacityAnim = (state: 'hidden' | 'visible', isLoading: boolean) => {
  const common = {
    config: { duration: 300 },
  };

  if (isLoading) {
    return {};
  }

  if (state === 'hidden') {
    return {
      ...common,
      from: { opacity: 1, transform: 'translateY(0%)' },
      to: { opacity: 0, transform: 'translateY(110%)' },
    };
  }

  return {
    ...common,
    from: { opacity: 0, transform: 'translateY(110%)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  };
};

export const WalkthroughModal = (): JSX.Element => {
  const { organization } = useOrganization();
  const { walkthroughOrganization, modalState, setModalState } =
    useWalkthroughModal();
  const updateWalkthroughOrganization = useUpdateWalkthroughOrganization({});

  const [props] = useSpring(
    () => opacityAnim(modalState, walkthroughOrganization.isLoading),
    [modalState],
  );

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: keyof WalkthroughOrganization,
  ) => {
    event.stopPropagation();

    updateWalkthroughOrganization.mutate({
      organizationSlug: organization.slug,
      [id]: event.target.checked,
    });
  };

  const steps: IStep[] = [
    {
      title: 'walkthrough.configureProductQuestionTitle',
      description: 'walkthrough.configureProductQuestionDescription',
      idCheckbox: 'configureProductQuestions',
      videoUrl: 'https://www.loom.com/embed/4039a4dbd94b4790813c21529d0f1217',
      checked: !!(
        walkthroughOrganization.data?.configureProductQuestions ?? false
      ),
    },
    {
      title: 'walkthrough.configureStore',
      description: 'walkthrough.configureStoreDescription',
      idCheckbox: 'configureStore',
      videoUrl:
        'https://www.loom.com/embed/3818dbb367ad4b28a2276ec16081ce64?sid=b24e26c6-c3ec-4377-bf14-0a0d24814989',
      checked: !!(walkthroughOrganization.data?.configureStore ?? false),
      values: {
        Shopify: (
          <a href="https://admin.shopify.com" target="_blank" rel="noreferrer">
            Shopify
          </a>
        ),
      },
    },
    {
      title: 'walkthrough.testYourPage',
      description: 'walkthrough.testYourPageDescription',
      idCheckbox: 'testStore',
      checked: !!(walkthroughOrganization.data?.testStore ?? false),
    },
  ];

  return (
    <animated.div
      style={props}
      css={css({
        display: 'flex',
        flexDirection: 'column',
        gap: spacings.lg,
        background: theme.palette.classic.white,
        width: '560px',
        position: 'fixed',
        zIndex: theme.zIndex.highest,
        bottom: spacings.xxl,
        right: getSmallSpacing(12),
        padding: spacings.lg,
        border: `1px solid ${theme.palette.grey100}`,
        boxShadow: theme.shadow.md,
        borderRadius: theme.borderRadius.md,
        opacity: 0,
        transform: 'translateY(110%)',
      })}
    >
      <div
        css={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}
      >
        <Typography variant="lg-md">
          <FormattedMessage id="walkthrough.title" />
        </Typography>

        <Button
          variant="blank"
          iconRight={<CrossIcon color={theme.palette.grey400} />}
          customCss={css({ color: theme.palette.grey400 })}
          onClick={() => setModalState('hidden')}
        >
          <FormattedMessage id="walkthrough.leaveMessage" />
        </Button>
      </div>
      <Accordion
        groups={formatGroups(steps, handleCheck)}
        closeOthersOnOpen={true}
      />
    </animated.div>
  );
};
