import {
  WithOnlyLogout,
  WithOrganization,
  WithRouteProtected,
  WithSuspense,
  WithWalkthroughModal,
} from 'hocs';

export const RouteBuilder = ({
  suspense,
  onlyLoggedOut,
  protected: protectedRoute,
  element,
  requireOrganization,
  walkthroughModalActivated,
}: {
  onlyLoggedOut: boolean;
  suspense: boolean;
  protected: boolean;
  element: JSX.Element;
  requireOrganization: boolean;
  walkthroughModalActivated: boolean;
}): JSX.Element | JSX.Element[] => {
  return (
    <WithSuspense suspense={suspense}>
      <WithRouteProtected protected={protectedRoute}>
        <WithOnlyLogout onlyLogout={onlyLoggedOut}>
          <WithOrganization requireOrganization={requireOrganization}>
            <WithWalkthroughModal
              // NOTE: Always false to disable walkthrough, keep it in case we do not use Intercom anymore
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              walkthroughModalActivated={false && walkthroughModalActivated}
            >
              {element}
            </WithWalkthroughModal>
          </WithOrganization>
        </WithOnlyLogout>
      </WithRouteProtected>
    </WithSuspense>
  );
};
