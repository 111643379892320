/*eslint max-lines: ["error", 281]*/
import { useMemo } from 'react';
import { IntlShape } from 'react-intl';

import { Organization } from '@dialog/organization-contracts';
import {
  CUSTOM_ATTRIBUTES_PREFIX,
  isCustomAttributeTraitName,
  Trait,
  TraitFieldNameType,
} from '@dialog/shared-types';

import {
  typeActivityScore,
  typeBoolean,
  typeDate,
  typeEvent,
  typeList,
  typeMoney,
  typeNumber,
  typeSmartLabel,
  typeString,
} from './types';

export const commonTraitsCategory: {
  categoryKey: string;
  attributes: Trait[];
}[] = [
  {
    categoryKey: 'dialog-attributes',
    attributes: [
      {
        name: 'smartLabels',
        type: typeSmartLabel,
        category: 'dialog-attributes',
      },
      {
        name: 'xmtpEnabled',
        type: typeBoolean,
        category: 'dialog-attributes',
      },
      {
        name: 'transactionActivityScore',
        additionalInformationTranslationKey:
          'home.table.additionalInformationsColumns.transactionActivityScore',
        type: typeActivityScore,
        category: 'dialog-attributes',
        description:
          'Score calculated based on the last transaction and the number of transactions performed on the blockchains',
      },
      {
        name: 'event.CAMPAIGN_SENT',
        type: typeEvent,
        category: 'dialog-attributes',
        flagsmithFeature: 'campaign_traits',
        rootLevelOnly: true,
        hideInColumns: true,
      },
      {
        name: 'event.CAMPAIGN_SEEN',
        type: typeEvent,
        category: 'dialog-attributes',
        flagsmithFeature: 'campaign_traits',
        rootLevelOnly: true,
        hideInColumns: true,
      },
      {
        name: 'event.CAMPAIGN_CLICKED',
        type: typeEvent,
        category: 'dialog-attributes',
        flagsmithFeature: 'campaign_traits',
        rootLevelOnly: true,
        hideInColumns: true,
      },
    ],
  },
  {
    categoryKey: 'transaction-activity',
    attributes: [
      {
        name: 'walletAddress',
        type: typeString,
        category: 'transaction-activity',
      },
      {
        name: 'transactionCount',
        type: typeNumber,
        category: 'transaction-activity',
      },
      {
        name: 'lastNativeTransaction',
        type: typeDate,
        category: 'transaction-activity',
        description: 'Date of the last native activity',
      },
      {
        name: 'lastNftTransfer',
        type: typeDate,
        category: 'transaction-activity',
        description: 'Date of the last nft activity performed',
      },
      {
        name: 'lastTokenTransfer',
        type: typeDate,
        category: 'transaction-activity',
        description: 'Date of the last token transfer performed',
      },
      {
        name: 'walletTotalValue',
        type: typeMoney,
        category: 'transaction-activity',
        description: 'Sum of all assets value in the portfolio',
      },
    ],
  },
  {
    categoryKey: 'portfolio',
    attributes: [
      {
        name: 'walletTokenValue',
        type: typeMoney,
        category: 'portfolio',
        description:
          'Value of portfolio for a specific asset in dollar (100 most popular assets)',
      },
      {
        name: 'blueChipTokenValue',
        type: typeMoney,
        category: 'portfolio',
        description:
          'Value of portfolio for the 100 most popular assets in dollar',
      },
      {
        name: 'tokenCount',
        type: typeNumber,
        category: 'portfolio',
        description: 'The number of assets in portfolio',
      },
      {
        name: 'tokens',
        type: typeList,
        category: 'portfolio',
        description: 'Tokens owned by this wallet',
      },
    ],
  },
  {
    categoryKey: 'nft-portfolio',
    attributes: [
      {
        name: 'walletNftValueDollar',
        type: typeMoney,
        category: 'nft-portfolio',
        description: 'Estimated value of the NFTs of this wallet',
        additionalInformationTranslationKey:
          'home.table.additionalInformationsColumns.walletNftValueDollar',
      },
      {
        name: 'nftCount',
        type: typeNumber,
        category: 'nft-portfolio',
        description: 'The total number of NFTs',
      },
      {
        name: 'nftCollections',
        type: typeList,
        category: 'nft-portfolio',
        description: 'NFTs owned by this wallet',
      },
    ],
  },
  {
    categoryKey: 'ens-domain',
    attributes: [{ name: 'ensName', type: typeString, category: 'ens-domain' }],
  },
  {
    categoryKey: 'off-chain-data',
    attributes: [
      { name: 'lastSeen', type: typeDate, category: 'off-chain-data' },
      { name: 'signUpDate', type: typeDate, category: 'off-chain-data' },
      { name: 'email', type: typeString, category: 'off-chain-data' },
      { name: 'name', type: typeString, category: 'off-chain-data' },
      { name: 'discordUsername', type: typeString, category: 'off-chain-data' },
      {
        name: 'telegramUsername',
        type: typeString,
        category: 'off-chain-data',
      },
      {
        name: 'twitterProfileUrl',
        type: typeString,
        category: 'off-chain-data',
      },
      { name: 'phone', type: typeString, category: 'off-chain-data' },
      { name: 'createdAt', type: typeDate, category: 'off-chain-data' },
      { name: 'sessionCount', type: typeNumber, category: 'off-chain-data' },
      { name: 'internalId', type: typeString, category: 'off-chain-data' },
    ],
  },
];

export const getCommonAndCustomTraits = (
  organization?: Organization,
): typeof commonTraitsCategory => {
  return commonTraitsCategory.concat([
    {
      categoryKey: 'customAttributes',
      attributes:
        organization?.customAttributes.map(attribute => ({
          name: `${CUSTOM_ATTRIBUTES_PREFIX}.${attribute.name}`,
          type: attribute.type,
          category: 'customAttributes',
        })) ?? [],
    },
  ]);
};

export const getFlattenedTraits = (organization?: Organization): Trait[] => {
  return getCommonAndCustomTraits(organization).flatMap(
    ({ attributes }) => attributes,
  );
};

export const useFlattenedTraits = (organization?: Organization): Trait[] => {
  return useMemo(() => getFlattenedTraits(organization), [organization]);
};

export const checkForAdditionalInformation = (
  traitName: string,
): string | undefined => {
  const flattedAttributes = commonTraitsCategory.flatMap(
    traitCat => traitCat.attributes,
  );

  const attribute = flattedAttributes.find(attr => attr.name === traitName);

  return attribute?.additionalInformationTranslationKey;
};

export const getTraitDisplayName = (
  trait: { name: TraitFieldNameType; label?: string },
  intl: IntlShape,
): string => {
  if (isCustomAttributeTraitName(trait.name)) {
    return trait.name.substring(CUSTOM_ATTRIBUTES_PREFIX.length + 1);
  }

  return intl.formatMessage({ id: `profile.wallet-key.${trait.name}` });
};
